import React, { useState, useEffect } from 'react';

const SOTable = ({ soData, threshold, onSOClick }) => {

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 400);
    const [isSmallishScreen, setIsSmallishScreen] = useState(window.innerWidth <= 600);
    
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 400);
            setIsSmallishScreen(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div id="SOTable">
            <table>
                <thead>
                    <tr>
                        <th title="The Number for the associated Student Outcome">SO</th>
                        <th title="Excellent: Students who scored between 90% - 100% on this CLO">
                            {!isSmallScreen ? 'Excellent' : 'E'}
                        </th>
                        <th title="Good: Students who scored between 80% - 89% on this CLO">
                            {!isSmallScreen ? 'Good' : 'G'}
                        </th>
                        <th title="Satisfactory: Students who scored between 70 – 79% on this CLO">
                            {!isSmallScreen ? 'Satisfactory' : 'S'}
                        </th>
                        <th title="Unsatisfactory: Students who scored less than 69% on this CLO">
                            {!isSmallScreen ? 'Unsatisfactory' : 'U'}
                        </th>
                        <th title="Null: Students who turned nothing in for this CLO">
                            {!isSmallScreen ? 'Null' : 'N'}
                        </th>
                    </tr>
                </thead>
                <tbody>
                {soData.map((so) => (
                        <tr key={so.SOID} style={{ backgroundColor: so.UnsatisfactoryPercent > threshold ? 'red' : 'white' }}>
                            <td>
                                <a
                                    href="#"
                                    className="so-link"
                                    onClick={() => onSOClick(so.SOID)}
                                >
                                    SO {so.SONumber}
                                    {!isSmallScreen && !isSmallishScreen && ` - ${so.SODescription}`}
                                </a>
                            </td>
                            <td>{so.ExcellentPercent}%</td>
                            <td>{so.GoodPercent}%</td>
                            <td>{so.SatisfactoryPercent}%</td>
                            <td>{so.UnsatisfactoryPercent}%</td>
                            <td>{so.NullPercent}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SOTable;
