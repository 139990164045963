import React, { useEffect, useState } from 'react';
import axios from 'axios';

const URL = window.env.REACT_APP_API_URL;

const CLOView = ({ soID, soData, onBackClick }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 400);
    const [isSmallishScreen, setIsSmallishScreen] = useState(window.innerWidth <= 600);
    const [CLOData, setCLOData] = useState([]);
    const [detailedCLOData, setDetailedCLOData] = useState([]);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 400);
            setIsSmallishScreen(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const filteredCLOData = soData.filter(info => info.SOID === soID);
        setCLOData(filteredCLOData);
    }, [soID, soData]);

    useEffect(() => {
        const fetchCLODetails = async () => {
            if (CLOData.length === 0) return;

            const CLOIDs = CLOData.map(clo => clo.CLOID);
            try {
                const detailedData = await Promise.all(
                    CLOIDs.map(async (CLOID) => {
                        const response = await axios.get(`${URL}getCLODetails/${CLOID}`);
                        const cloDetails = response.data[0];
                        return { CLOID, ...cloDetails };
                    })
                );
                setDetailedCLOData(detailedData);
            } catch (error) {
                console.error('Error fetching CLO details:', error);
            }
        };

        fetchCLODetails();
    }, [CLOData]);

    return (
        <div id="CLOView">
            {CLOData.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                             <th title="The Number for this Course Learning Objective">CLO</th>
                             <th title="The course associated with this CLO">Course</th>
                             <th title="The specific section of the course that uses this CLO">Section</th>
                             <th title="Excellent: Students who scored between 90% - 100% on this CLO">
                                {!isSmallScreen ? 'Excellent' : 'E'}
                            </th>
                             <th title="Good: Students who scored between 80% - 89% on this CLO">
                                {!isSmallScreen ? 'Good' : 'G'}
                            </th>
                             <th title="Satisfactory: Students who scored between 70 – 79% on this CLO">
                                {!isSmallScreen ? 'Satisfactory' : 'S'}
                            </th>
                             <th title="Unsatisfactory: Students who scored less than 69% on this CLO">
                                {!isSmallScreen ? 'Unsatisfactory' : 'U'}
                            </th>
                             <th title="Null: Students who turned nothing in for this CLO">
                                {!isSmallScreen ? 'Null' : 'N'}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {detailedCLOData.map((CLO) => (
                            <tr key={CLO.CLOID}>
                                <td>CLO {CLO.CLONumber} {!isSmallScreen && !isSmallishScreen && ` - ${CLO.CLOComments}`}</td>
                                <td>{CLO.CourseNum}</td>
                                <td>{CLO.SectionNum}</td>
                                <td>{CLO.ExcellentCount}</td>
                                <td>{CLO.GoodCount}</td>
                                <td>{CLO.SatisfactoryCount}</td>
                                <td>{CLO.UnsatisfactoryCount}</td>
                                <td>{CLO.NullCount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className='PCenter'>No Course Learning Outcomes (CLOs) associated with this Student Outcome (SO).</p>
            )}
            <button onClick={onBackClick}>Back</button>
        </div>
    );
};

export default CLOView;
