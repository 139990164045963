import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useAuth } from '../../context/AuthContext';
import { selectStyles } from '../../selectStyle';

const URL = window.env.REACT_APP_API_URL;

const CLOManagement = () => {
    const [measures, setMeasures] = useState([]);
    const [assessments, setAssessments] = useState([]);
    const [sections, setSections] = useState([]);
    const [CLOs, setCLOs] = useState([]);
    const [newCLONumber, setNewCLONumber] = useState('');
    const [newCLOComments, setNewCLOComments] = useState('');
    const [selectedMeasure, setSelectedMeasure] = useState(null);
    const [selectedAssessment, setSelectedAssessment] = useState(null);
    const [selectedCLO, setSelectedCLO] = useState(null);
    const [editCLONumber, setEditCLONumber] = useState('');
    const [editCLOComments, setEditCLOComments] = useState('');
    const [editMeasure, setEditMeasure] = useState(null);
    const [editAssessment, setEditAssessment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const measuresResponse = await axios.get(`${URL}getMeasures`);
            setMeasures(measuresResponse.data);

            const assessmentsResponse = await axios.get(`${URL}getAssessments/${user.UserID}`);
            setAssessments(assessmentsResponse.data);

            const sectionsResponse = await axios.get(`${URL}getSections/${user.UserID}`);
            setSections(sectionsResponse.data);

            const CLOsResponse = await axios.get(`${URL}getCLOs/${user.UserID}`);
            setCLOs(CLOsResponse.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError('Failed to load data.');
        } finally {
            setLoading(false);
        }
    };

    const handleAssessmentChange = (selectedOption) => {
        setSelectedAssessment(selectedOption);
        setSelectedCLO(null); // Reset selected CLO when changing assessment
    };

    const handleCLOChange = (selectedOption) => {
        setSelectedCLO(selectedOption);
        const CLO = CLOs.find(clo => clo.CLOID == selectedOption.value);
        if (CLO) {
            setEditCLONumber(CLO.CLONumber);
            setEditCLOComments(CLO.CLOComments);
            setEditMeasure({ value: CLO.MeasureID, label: measures.find(m => m.MeasureID == CLO.MeasureID)?.MeasureName });
            setEditAssessment({ value: CLO.AssessID, label: getAssessmentName(assessments.find(a => a.AssessID == CLO.AssessID)) });
        }
    };

    const addCLO = async (e) => {
        setLoading(true);
        setError(null);
        e.preventDefault();
        try {
            await axios.post(`${URL}addCLO`, {
                CLONumber: newCLONumber,
                CLOComments: newCLOComments,
                MeasureID: selectedMeasure.value,
                AssessID: selectedAssessment.value,
                User: user
            });
            setNewCLONumber('');
            setNewCLOComments('');
            setSelectedMeasure(null);
            setSelectedAssessment(null);
            setSelectedCLO(null);
            setEditCLONumber('');
            setEditCLOComments('');
            setEditMeasure(null);
            setEditAssessment(null);
            alert("CLO Added");
            fetchData();
        } catch (error) {
            console.error("Error adding CLO:", error);
            setError('Failed to add CLO');
        } finally {
            setLoading(false);
        }
    };

    const editCLO = async (e) => {
        setLoading(true);
        setError(null);
        e.preventDefault();
        try {
            await axios.put(`${URL}editCLO`, {
                CLOID: selectedCLO.value,
                CLONumber: editCLONumber,
                CLOComments: editCLOComments,
                MeasureID: editMeasure.value,
                AssessID: editAssessment.value,
                User: user
            });
            setSelectedCLO(null);
            setEditCLONumber('');
            setEditCLOComments('');
            setEditMeasure(null);
            setEditAssessment(null);
            setNewCLONumber('');
            setNewCLOComments('');
            setSelectedMeasure(null);
            setSelectedAssessment(null);
            alert("CLO Changed");
            fetchData();
        } catch (error) {
            console.error("Error editing CLO:", error);
            setError('Failed to edit CLO');
        } finally {
            setLoading(false);
        }
    };

    const removeCLO = async (e) => {
        setLoading(true);
        setError(null);
        e.preventDefault();
        try {
            await axios.delete(`${URL}removeCLO`, {
                data: {
                    CLOID: selectedCLO.value,
                    User: user
                }
            });
            setSelectedCLO(null);
            setNewCLONumber('');
            setNewCLOComments('');
            setSelectedMeasure(null);
            setSelectedAssessment(null);
            setEditCLONumber('');
            setEditCLOComments('');
            setEditMeasure(null);
            setEditAssessment(null);
            alert("CLO Removed");
            fetchData();
        } catch (error) {
            console.error("Error removing CLO:", error);
            setError('Failed to remove CLO');
        } finally {
            setLoading(false);
        }
    };

    const getAssessmentName = (assessment) => {
        const section = sections.find(sec => sec.SectionID === assessment.SectionID);
        return `${assessment.CYear} - ${section?.CourseNum}/${section?.SectionNum}`;
    };

    const measureOptions = measures.map(measure => ({
        value: measure.MeasureID,
        label: measure.MeasureName
    }));

    const assessmentOptions = assessments.map(assessment => ({
        value: assessment.AssessID,
        label: getAssessmentName(assessment)
    }));

    const filteredCLOs = selectedAssessment
        ? CLOs.filter(clo => clo.AssessID == selectedAssessment.value)
        : CLOs;

    const CLOOptions = filteredCLOs.map(CLO => ({
        value: CLO.CLOID,
        label: `${CLO.CLONumber} - ${measures.find(m => m.MeasureID == CLO.MeasureID)?.MeasureName}`
    }));

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    return (
        <section id="CLOManager">
            <h1>CLO Management</h1>
            <h2>Add CLO</h2>
            <form onSubmit={addCLO}>
                <input
                    type="number"
                    id="newCLONumber"
                    placeholder="Enter CLO number"
                    min="0"
                    value={newCLONumber}
                    onChange={(e) => setNewCLONumber(e.target.value)}
                    required
                />
                <textarea
                    id="newCLOComments"
                    placeholder="Enter CLO comments"
                    maxLength="1000"
                    value={newCLOComments}
                    onChange={(e) => setNewCLOComments(e.target.value)}
                ></textarea>
                <Select
                    value={selectedMeasure}
                    onChange={setSelectedMeasure}
                    options={measureOptions}
                    placeholder="-- Select Measure --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                <Select
                    value={selectedAssessment}
                    onChange={handleAssessmentChange}
                    options={assessmentOptions}
                    placeholder="-- Select Assessment Plan --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                <button type="submit" disabled={loading}>Submit</button>
            </form>
            <h2>Edit CLO</h2>
            <form onSubmit={editCLO}>
                <Select
                    value={selectedAssessment}
                    onChange={handleAssessmentChange}
                    options={assessmentOptions}
                    placeholder="-- Select Assessment Plan --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                {selectedAssessment && (
                    <Select
                        value={selectedCLO}
                        onChange={handleCLOChange}
                        options={CLOOptions}
                        placeholder="-- Select CLO --"
                        isSearchable
                        required
                        styles={selectStyles}
                    />
                )}
                {selectedCLO && (
                    <>
                        <input
                            type="number"
                            id="editCLONumber"
                            placeholder="Enter new CLO number"
                            min="0"
                            value={editCLONumber}
                            onChange={(e) => setEditCLONumber(e.target.value)}
                            required
                        />
                        <textarea
                            id="editCLOComments"
                            placeholder="Enter new CLO comments"
                            maxLength="1000"
                            value={editCLOComments}
                            onChange={(e) => setEditCLOComments(e.target.value)}
                        ></textarea>
                        <Select
                            value={editMeasure}
                            onChange={setEditMeasure}
                            options={measureOptions}
                            placeholder="-- Select Measure --"
                            isSearchable
                            required
                            styles={selectStyles}
                        />
                        <Select
                            value={editAssessment}
                            onChange={setEditAssessment}
                            options={assessmentOptions}
                            placeholder="-- Select Assessment Plan --"
                            isSearchable
                            required
                            styles={selectStyles}
                        />
                    </>
                )}
                <button type="submit" disabled={loading}>Submit</button>
            </form>
            <h2>Remove CLO</h2>
            <form onSubmit={removeCLO}>
                <Select
                    value={selectedAssessment}
                    onChange={handleAssessmentChange}
                    options={assessmentOptions}
                    placeholder="-- Select Assessment Plan --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                {selectedAssessment && (
                    <Select
                        value={selectedCLO}
                        onChange={handleCLOChange}
                        options={CLOOptions}
                        placeholder="-- Select CLO --"
                        isSearchable
                        required
                        styles={selectStyles}
                    />
                )}
                <button type="submit" disabled={loading}>Confirm</button>
            </form>
        </section>
    );
};

export default CLOManagement;
