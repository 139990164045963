// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getUserSession, setUserSession, clearUserSession } from '../utils/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const { user, isAdmin } = getUserSession();
        setUser(user);
        setIsAdmin(isAdmin);
        setLoading(false);
    }, []);

    const login = (user, isAdmin) => {
        setUserSession(user, isAdmin);
        setUser(user);
        setIsAdmin(isAdmin);
        setLoading(false);
    };

    const logout = () => {
        clearUserSession();
        setUser(null);
        setIsAdmin(false);
    };

    const updateUser = (updatedUser) => {
        setUserSession(updatedUser, isAdmin);
        setUser(updatedUser);
    };

    return (
        <AuthContext.Provider value={{ user, isAdmin, loading, login, logout, updateUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);