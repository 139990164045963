import React, { useState, useEffect } from 'react';
import axios from 'axios';
import YearSelector from './YearSelector';
import FCARMainTable from './FCARMainTable';
import FCARMeasuresView from './FCARMeasuresView';
import FCARReflectionsView from './FCARReflectionsView';
import FCARDocumentsView from './FCARDocumentsView';
import { useAuth } from '../context/AuthContext';

const URL = window.env.REACT_APP_API_URL;

const FCARManager = () => {
    const [selectedYear, setSelectedYear] = useState('');
    const [view, setView] = useState('main'); // 'main', 'measures', 'reflections', 'documents'
    const [fcarData, setFcarData] = useState([]);
    const [currentFcar, setCurrentFcar] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user, isAdmin } = useAuth();

    useEffect(() => {
        fetchFcarData();
    }, [selectedYear]);

    const fetchFcarData = async () => {
        setLoading(true);
        setError(null);
        const endpoint = selectedYear ? `${URL}fillFCAR/${user.UserID}/${selectedYear}` : `${URL}fillFCAR/${user.UserID}`;
        try {
            const response = await axios.get(endpoint);
            setFcarData(groupByFCARID(response.data));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching FCAR data:', error);
            setError('Failed to load FCAR data');
            setLoading(false);
        }
    };

    const groupByFCARID = (data) => {
        const groupedData = data.reduce((acc, item) => {
            const existingFCAR = acc.find(fcar => fcar.FCARID === item.FCARID);
            if (existingFCAR) {
                existingFCAR.CLOs.push({
                    CLOID: item.CLOID,
                    CLONumber: item.CLONumber,
                    CLOComments: item.CLOComments,
                    ExcellentCount: item.ExcellentCount,
                    GoodCount: item.GoodCount,
                    SatisfactoryCount: item.SatisfactoryCount,
                    UnsatisfactoryCount: item.UnsatisfactoryCount,
                    NullCount: item.NullCount,
                    MeasureID: item.MeasureID,
                    MeasureName: item.MeasureName,
                    MeasureDescription: item.MeasureDescription
                });
            } else {
                acc.push({
                    FCARID: item.FCARID,
                    FCARYear: item.FCARYear,
                    FCARApproved: item.FCARApproved,
                    Modifications: item.Modifications,
                    Reflection: item.Reflection,
                    StudentFeedback: item.StudentFeedback,
                    Improvement: item.Improvement,
                    SectionID: item.SectionID,
                    SectionNum: item.SectionNum,
                    CourseID: item.CourseID,
                    CourseNum: item.CourseNum,
                    CourseName: item.CourseName,
                    CourseDescription: item.CourseDescription,
                    FacultyName: item.FacultyName,
                    CLOs: [{
                        CLOID: item.CLOID,
                        CLONumber: item.CLONumber,
                        CLOComments: item.CLOComments,
                        ExcellentCount: item.ExcellentCount,
                        GoodCount: item.GoodCount,
                        SatisfactoryCount: item.SatisfactoryCount,
                        UnsatisfactoryCount: item.UnsatisfactoryCount,
                        NullCount: item.NullCount,
                        MeasureID: item.MeasureID,
                        MeasureName: item.MeasureName,
                        MeasureDescription: item.MeasureDescription
                    }]
                });
            }
            return acc;
        }, []);
        return groupedData;
    };

    const toggleApproval = async (FCARID, approved) => {
        setLoading(true);
        try {
            const response = await axios.post(`${URL}toggleFCARApproval`, {
                FCARID,
                approved,
                userID: user.UserID
            });
            if (response.data.success) {
                setLoading(false);
                fetchFcarData();
            } else {
                setLoading(false);
                setError('Failed to toggle Approval');
                console.error('Failed to toggle approval:', response.data.error);
            }
        } catch (error) {
            setLoading(false);
            setError('Failed to toggle Approval');
            console.error('Error toggling approval:', error);
        }
    };

    const handleViewChange = (view, fcar = null) => {
        setView(view);
        setCurrentFcar(fcar);
    };

    return (
        <div>
            <h1>FCAR Manager</h1>
            <YearSelector onSelectYear={setSelectedYear} />
            {loading && <div>Loading...</div>}
            {error && <div>Error: {error}</div>}
            {!loading && !error && view === 'main' && (
                fcarData.length > 0 ? (
                    <FCARMainTable
                        fcarData={fcarData}
                        onViewChange={handleViewChange}
                        isAdmin={isAdmin}
                        onToggleApproval={toggleApproval}
                    />
                ) : (
                    <p className='PCenter'>No FCAR data available.</p>
                )
            )}
            {view === 'measures' && currentFcar && <FCARMeasuresView fcar={currentFcar} onViewChange={handleViewChange} fetchFcarData={fetchFcarData} />}
            {view === 'reflections' && currentFcar && <FCARReflectionsView fcar={currentFcar} onViewChange={handleViewChange} fetchFcarData={fetchFcarData} />}
            {view === 'documents' && currentFcar && <FCARDocumentsView fcar={currentFcar} onViewChange={handleViewChange} fetchFcarData={fetchFcarData} />}
        </div>
    );
}

export default FCARManager;
