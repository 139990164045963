// src/pages/FCARPage.js
import React from 'react';
import FCARManager from '../components/FCARManager';

const CreateFCARPage = () => {
  return (
    <div className="main-content">
      <h1>FCAR</h1>
      <hr />
      <FCARManager />
    </div>
  );
};

export default CreateFCARPage;
