import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { selectStyles } from '../selectStyle';

const URL = window.env.REACT_APP_API_URL;

const YearSelector = ({ onSelectYear }) => {
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      setLoading(true);
        fetch(`${URL}years`)
          .then(response => response.json())
          .then(data => {
            setYears(data)
            selectCurrentYear(data); // Select the current year or nearest year from the fetched data
            setLoading(false);
            })
          .catch(error => {
            console.error('Error fetching years:', error);
            setError('Error fetching years');
            setLoading(false);

          });
      }, []);

      // Grabs the current year or the nearest available previous year
      const selectCurrentYear = (years) => {
        const currentYear = new Date().getFullYear();
        const closestYear = years.reduce((prev, curr) => {
            return (Math.abs(curr.CYear - currentYear) < Math.abs(prev.CYear - currentYear) ? curr : prev);
        });
        const selectedOption = { value: closestYear.CYear, label: closestYear.CYear.toString() };
        setSelectedYear(selectedOption);
        onSelectYear(closestYear.CYear);
      }

      const handleYearChange = (selectedOption) => {
        setSelectedYear(selectedOption);
        onSelectYear(selectedOption ? selectedOption.value : '');
    };

    const yearOptions = [
        { value: '', label: '-- Select Year --' },
        ...years.map(year => ({ value: year.CYear, label: year.CYear.toString() }))
    ];

    if (loading) {
      return <div>Loading years...</div>;
  }

  if (error) {
      return <div>{error}</div>;
  }

      return (
        <Select
            value={selectedYear}
            onChange={handleYearChange}
            options={yearOptions}
            placeholder="-- Select Year --"
            isSearchable
            styles={selectStyles}
        />
      );
};

export default YearSelector;