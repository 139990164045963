import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const URL = window.env.REACT_APP_API_URL;

const AssessmentPlanTable = ({ assessmentPlans, onToggleApproval, onHandleDelete, isAdmin }) => {
    const navigate = useNavigate();
    const [faculty, setFaculty] = useState([]);
    const [connections, setConnections] = useState([]);
    const [filteredFaculty, setFilteredFaculty] = useState({});
    const [selectedFaculty, setSelectedFaculty] = useState({});
    const { user } = useAuth();

    useEffect(() => {
        const fetchFacultyAndConnections = async () => {
            try {
                const facultyResponse = await axios.get(`${URL}getFaculty/${user.UserID}`);
                const connectionsResponse = await axios.get(`${URL}getConnections/${user.UserID}`);
                setFaculty(facultyResponse.data);
                setConnections(connectionsResponse.data);
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };

        fetchFacultyAndConnections();
    }, []);

    useEffect(() => {
        const filterFaculty = () => {
            const filtered = {};
            assessmentPlans.forEach(plan => {
                const associatedFaculty = connections
                    .filter(conn => conn.CourseID === plan.CourseID)
                    .map(conn => faculty.find(fac => fac.UserID === conn.UserID))
                    .filter(fac => fac !== undefined);
                filtered[plan.AssessID] = associatedFaculty;
            });
            setFilteredFaculty(filtered);
        };

        if (faculty.length > 0 && connections.length > 0) {
            filterFaculty();
        }
    }, [faculty, connections, assessmentPlans]);

    const handleViewDetails = (assessID) => {
        navigate(`/viewassessments/${assessID}`);
    };

    const handleFacultyChange = (selectedOption, assessID) => {
        setSelectedFaculty(prev => ({
            ...prev,
            [assessID]: selectedOption
        }));
    };

    const handleUpdateFaculty = async (assessID) => {
        const selectedFacultyMember = selectedFaculty[assessID];

        if (!selectedFacultyMember) {
            alert('Please select a faculty member.');
            return;
        }

        try {
            const response = await axios.put(`${URL}updateAssessmentPlanFaculty`, {
                assessID,
                facultyID: selectedFacultyMember.value
            });

            if (response.data.success) {
                alert('Faculty updated successfully.');
                window.location.reload(); 
            } else {
                alert('Failed to update faculty.');
            }
        } catch (error) {
            console.error('Error updating faculty:', error);
            alert('Failed to update faculty.');
        }
    };
    return (
        <table>
            <thead>
                <tr>
                    <th title="The year of this assessment plan" className="optional-column">Year</th>
                    <th title="The course number for this assessment plan" >Course</th>
                    <th title="The section number for this assessment plan">Section</th>
                    <th title="The Instructor who associated with this assessment plan">Instructor</th>
                    <th title="Has this assessment plan been approved for usage?" >Approved?</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {assessmentPlans.map(plan => (
                    <tr key={plan.AssessID}>
                        <td className="optional-column">{plan.CYear}</td>
                        <td>{plan.CourseNum} - {plan.CourseName}</td>
                        <td>{plan.SectionNum}</td>
                        <td>
                            {isAdmin ? (
                                <>
                                    <Select
                                        value={selectedFaculty[plan.AssessID] || null}
                                        onChange={(option) => handleFacultyChange(option, plan.AssessID)}
                                        options={filteredFaculty[plan.AssessID]?.map(fac => ({
                                            value: fac.UserID,
                                            label: fac.FullName
                                        })) || []}
                                        placeholder= {plan.FullName}
                                        isSearchable
                                    />
                                    <button onClick={() => handleUpdateFaculty(plan.AssessID)}>
                                        Update Faculty
                                    </button>
                                </>
                            ) : (
                                plan.FullName
                            )}
                        </td>
                        <td>{plan.Approved ? 'Yes' : 'No'}</td>
                        <td>
                            {isAdmin && (
                                <>
                                    <button onClick={() => onToggleApproval(plan.AssessID, !plan.Approved)}>
                                        {plan.Approved ? 'Unapprove' : 'Approve'}
                                    </button>
                                    <button onClick={() => onHandleDelete(plan.AssessID)}>
                                        Delete
                                    </button>
                                </>
                            )}
                            <button onClick={() => handleViewDetails(plan.AssessID)}>View Details</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default AssessmentPlanTable;