import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useAuth } from '../../context/AuthContext';
import { selectStyles } from '../../selectStyle';

const URL = window.env.REACT_APP_API_URL;

const MeasureManager = () => {
    const [measures, setMeasures] = useState([]);
    const [newMeasureName, setNewMeasureName] = useState('');
    const [newMeasureDescription, setNewMeasureDescription] = useState('');
    const [editMeasure, setEditMeasure] = useState(null);
    const [deleteMeasure, setDeleteMeasure] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        fetchMeasures();
    }, []);

    const fetchMeasures = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${URL}getMeasures`);
            setMeasures(response.data);
        } catch (error) {
            console.error("Error fetching measures:", error);
            setError('Failed to fetch measures.');
        } finally {
            setLoading(false);
        }
    };

    const handleAddMeasure = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.post(`${URL}addMeasure`, {
                MeasureName: newMeasureName,
                MeasureDescription: newMeasureDescription,
                User: user
            });
            setNewMeasureName('');
            setNewMeasureDescription('');
            alert("Measure Created");
            fetchMeasures();
        } catch (error) {
            console.error("Error adding measure:", error);
            setError('Failed to add measure.');
        } finally {
            setLoading(false);
        }
    };

    const handleEditMeasure = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.put(`${URL}editMeasure`, {
                MeasureID: editMeasure.value,
                MeasureName: editMeasure.label,
                MeasureDescription: editMeasure.description,
                User: user
            });
            setEditMeasure(null);
            alert("Measure Changed");
            fetchMeasures();
        } catch (error) {
            console.error("Error editing measure:", error);
            setError('Failed to edit measure.');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteMeasure = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.delete(`${URL}removeMeasure`, {
                data: {
                    MeasureID: deleteMeasure.value,
                    User: user
                }
            });
            setDeleteMeasure(null);
            alert("Measure Removed");
            fetchMeasures();
        } catch (error) {
            console.error("Error deleting measure:", error);
            setError('Failed to delete measure.');
        } finally {
            setLoading(false);
        }
    };

    const handleEditMeasureChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {
            const selectedMeasure = measures.find(measure => measure.MeasureID === selectedOption.value);
            if (selectedMeasure) {
                setEditMeasure({
                    value: selectedMeasure.MeasureID,
                    label: selectedMeasure.MeasureName,
                    description: selectedMeasure.MeasureDescription
                });
            }
        } else {
            setEditMeasure(null);
        }
    };
    

    const measureOptions = [
        { value: '', label: '-- Select Measure --' },
        ...measures.map(measure => ({
            value: measure.MeasureID,
            label: measure.MeasureName,
            description: measure.MeasureDescription
        }))
    ];

    return (
        <section id="measureManager">
            <h1>Measure Manager</h1>
            {loading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            <h2>Add Measure</h2>
            <form onSubmit={handleAddMeasure}>
                <input
                    type="text"
                    id="newMeasureName"
                    placeholder="Enter measure name"
                    value={newMeasureName}
                    onChange={(e) => setNewMeasureName(e.target.value)}
                    maxLength="50"
                    required
                />
                <textarea
                    id="newMeasureDescription"
                    placeholder="Enter measure description"
                    value={newMeasureDescription}
                    onChange={(e) => setNewMeasureDescription(e.target.value)}
                    maxLength="500"
                />
                <button type="submit">Submit</button>
            </form>
            <h2>Edit Measure</h2>
            <form onSubmit={handleEditMeasure}>
                <Select
                    value={editMeasure}
                    onChange={handleEditMeasureChange}
                    options={measureOptions}
                    placeholder="-- Select Measure --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                {editMeasure && (
                    <>
                        <input
                            type="text"
                            id="editMeasureName"
                            placeholder="Enter new measure name"
                            value={editMeasure.label}
                            onChange={(e) =>
                                setEditMeasure((prev) => ({ ...prev, label: e.target.value }))
                            }
                            maxLength="50"
                            required
                        />
                        <textarea
                            id="editMeasureDescription"
                            placeholder="Enter new measure description"
                            value={editMeasure.description}
                            onChange={(e) =>
                                setEditMeasure((prev) => ({ ...prev, description: e.target.value }))
                            }
                            maxLength="500"
                        />
                    </>
                )}
                <button type="submit">Submit</button>
            </form>
            <h2>Delete Measure</h2>
            <form onSubmit={handleDeleteMeasure}>
                <Select
                    value={deleteMeasure}
                    onChange={setDeleteMeasure}
                    options={measureOptions}
                    placeholder="-- Select Measure --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                <button type="submit" disabled={loading}>Confirm</button>
            </form>
        </section>
    );
}

export default MeasureManager;
