// src/components/admin/AdminNavigation.js
import React, { useState, useEffect } from 'react';

const AdminNavigation = () => {

  const [isSmallishScreen, setIsSmallishScreen] = useState(window.innerWidth <= 992);

    useEffect(() => {
        const handleResize = () => {
          setIsSmallishScreen(window.innerWidth <= 992);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
  return (
    <nav className="admin-nav">
      <ul>
        <li><a href="#year-management">{!isSmallishScreen ? 'Year Management' : 'Year'}</a></li>
        <li><a href="#course-management">{!isSmallishScreen ? 'Course Management' : 'Course'}</a></li>
        <li><a href="#use-course-connection-manager">{!isSmallishScreen ? 'Use Course Connection Manager' : 'UCCM'}</a></li>
        <li><a href="#section-management">{!isSmallishScreen ? 'Section Managementt' : 'Section'}</a></li>
        <li><a href="#so-management">{!isSmallishScreen ? 'SO Management' : 'SO'}</a></li>
        <li><a href="#clo-management">{!isSmallishScreen ? 'CLO Management' : 'CLO'}</a></li>
        <li><a href="#soclo-connection-manager">{!isSmallishScreen ? 'SO-CLO Connection Manager' : 'SO-CLO'}</a></li>
        <li><a href="#measure-manager">{!isSmallishScreen ? 'Measure Manager' : 'Measure'}</a></li>
      </ul>
    </nav>
  );
};

export default AdminNavigation;
