import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useAuth } from '../../context/AuthContext';
import { selectStyles } from '../../selectStyle';

const URL = window.env.REACT_APP_API_URL;

const YearManagement = () => {
    const [years, setYears] = useState([]);
    const [newYear, setNewYear] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();
  
    useEffect(() => {
      fetchYears();
    }, []);

    const fetchYears = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${URL}years`);
            setYears(response.data);
        } catch (error) {
            console.error("Error fetching Years:", error);
            setError('Failed to fetch years');
        } finally {
            setLoading(false);
        }
    };

    const addYear = async (e) => {
        setLoading(true);
        setError(null);
        e.preventDefault();
        try {
            await axios.post(`${URL}addYear`, { CYear: newYear, User: user });
            setNewYear('');
            alert(`${newYear} added!`);
            fetchYears();
        } catch (error) {
            console.error("Error adding year:", error);
            setError('Failed to add year');
        } finally {
            setLoading(false);
        }
    };

    const removeYear = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.post(`${URL}removeYear`, { CYear: selectedYear.value, User: user });
            setSelectedYear(null);
            alert(`${selectedYear.value} removed`);
            fetchYears();
        } catch (error) {
            console.error("Error removing year:", error);
            setError('Failed to remove year');
        } finally {
            setLoading(false);
        }
    };

    const handleYearChange = (selectedOption) => {
        setSelectedYear(selectedOption);
    };

    const yearOptions = [
        { value: '', label: '-- Select Year --' },
        ...years.map(year => ({ value: year.CYear, label: year.CYear.toString() }))
    ];

    return (
        <section id="yearManager">
            <h1>Year Management</h1>
            {loading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            <h2>Add Year</h2>
            <form onSubmit={addYear}>
                <input
                    type="number"
                    value={newYear}
                    onChange={(e) => setNewYear(e.target.value)}
                    placeholder="Enter Year Here"
                    min="2024"
                    max="3000"
                    required
                />
                <button type="submit" disabled={loading}>Submit</button>
            </form>
            <h2>Remove Year</h2>
            <form onSubmit={removeYear}>
            <Select
                    value={selectedYear}
                    onChange={handleYearChange}
                    options={yearOptions}
                    placeholder="-- Select Year --"
                    isSearchable
                    styles={selectStyles}
                />
                <button type="submit" disabled={loading}>Confirm</button>
            </form>
        </section>
    )
}

export default YearManagement;