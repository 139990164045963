import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useAuth } from '../context/AuthContext';
import { selectStyles } from '../selectStyle';

const URL = window.env.REACT_APP_API_URL;

const CourseSelector = ({ onSelectCourse }) => {
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        fetchCourses();
    }, []);

    const fetchCourses = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${URL}courses/${user.UserID}`);
            setCourses(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching courses:', error);
            setError('Failed to load courses');
            setLoading(false);
        }
    };

    const handleCourseChange = (selectedOption) => {
        setSelectedCourse(selectedOption);
        onSelectCourse(selectedOption ? selectedOption.value : '');
    };

    const courseOptions = [
        { value: '', label: '-- Select Course --' },
        ...courses.map(course => ({
            value: course.CourseID,
            label: `${course.CourseNum} - ${course.CourseName}`
        }))
    ];

    return (
        <>
            {loading && <div>Loading courses...</div>}
            {error && <div>Error: {error}</div>}

            {!loading && !error && (
                <Select
                    value={selectedCourse}
                    onChange={handleCourseChange}
                    options={courseOptions}
                    placeholder="-- Select Course --"
                    isSearchable
                    styles={selectStyles}
                />
            )}
        </>
    );
};

export default CourseSelector;