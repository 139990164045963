import React, { useState, useEffect } from 'react';
import axios from 'axios';
import YearSelector from './YearSelector';
import SOTable from './SOTable';
import CLOView from './CLOView';
import { useAuth } from '../context/AuthContext';

const URL = window.env.REACT_APP_API_URL;

const SOManager = () => {
    const [selectedYear, setSelectedYear] = useState('');
    const [soData, setSOData] = useState([]);
    const [groupedSOData, setGroupedSOData] = useState([]);
    const [selectedSO, setSelectedSO] = useState(null);
    const [threshold, setThreshold] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        fetchSOData(selectedYear);
        fetchThreshold();
    }, [selectedYear]);

    const fetchSOData = async (year) => {
        setLoading(true);
        setError(null);
        const endpoint = year ? `${URL}fillSO/${user.UserID}/${year}` : `${URL}fillSO/${user.UserID}`;
        try {
            const response = await axios.get(endpoint);
            const data = response.data;
            setSOData(data);
            groupAndCalculateSOData(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching SO data:', error);
            setError('Error fetching SO data');
            setLoading(false);
        }
    };

    const fetchThreshold = async () => {
        try {
            const response = await axios.get(`${URL}getThreshold`);
            setThreshold(response.data.Threshold);
        } catch (error) {
            console.error('Error fetching threshold:', error);
            setError('Failed to load threshold data.');
        }
    };

    const groupAndCalculateSOData = (data) => {
        let SOTableGrouped = {};

        data.forEach(info => {
            const thisSO = `${info.SOID}`;

            if (!SOTableGrouped[thisSO]) {
                SOTableGrouped[thisSO] = {
                    SONumber: info.SONumber,
                    SODescription: info.SODescription,
                    Total: 0,
                    Excellent: 0,
                    Good: 0,
                    Satisfactory: 0,
                    Unsatisfactory: 0,
                    Null: 0
                };
            }

            SOTableGrouped[thisSO].Total += (info.ExcellentCount + info.GoodCount + info.SatisfactoryCount + info.UnsatisfactoryCount + info.NullCount);
            SOTableGrouped[thisSO].Excellent += info.ExcellentCount;
            SOTableGrouped[thisSO].Good += info.GoodCount;
            SOTableGrouped[thisSO].Satisfactory += info.SatisfactoryCount;
            SOTableGrouped[thisSO].Unsatisfactory += info.UnsatisfactoryCount;
            SOTableGrouped[thisSO].Null += info.NullCount;
        });

        const groupedData = Object.keys(SOTableGrouped).map(SOID => {
            const SOData = SOTableGrouped[SOID];
            const total = SOData.Total;
            return {
                SOID,
                SONumber: SOData.SONumber,
                SODescription: SOData.SODescription,
                ExcellentPercent: total ? (SOData.Excellent / total * 100).toFixed(2) : 0,
                GoodPercent: total ? (SOData.Good / total * 100).toFixed(2) : 0,
                SatisfactoryPercent: total ? (SOData.Satisfactory / total * 100).toFixed(2) : 0,
                UnsatisfactoryPercent: total ? (SOData.Unsatisfactory / total * 100).toFixed(2) : 0,
                NullPercent: total ? (SOData.Null / total * 100).toFixed(2) : 0
            };
        });

        setGroupedSOData(groupedData);
    };

    const handleSOClick = (soID) => {
        setSelectedSO(Number(soID)); // Convert soID to a number
    };
    

    const handleBackClick = () => {
        setSelectedSO(null);
    };

    return (
        <div>
            <h1>SO Manager</h1>
            <YearSelector onSelectYear={setSelectedYear} />
            {loading && <div>Loading SO data...</div>}
            {error && <div>{error}</div>}
            {!loading && !error && !selectedSO && (
                groupedSOData.length > 0 ? (
                    <SOTable soData={groupedSOData} threshold={threshold} onSOClick={handleSOClick} />
                ) : (
                    <p className='PCenter'>No Student Outcomes (SOs) available for the selected year.</p>
                )
            )}
            {selectedSO && <CLOView soID={selectedSO} soData={soData} onBackClick={handleBackClick} />}
        </div>
    );
};

export default SOManager;
