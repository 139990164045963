import React, { useState, useEffect } from 'react';
import axios from 'axios';
import placeholder from '../images/cidmlogo.png';

const URL = window.env.REACT_APP_API_URL;

const AssessmentPlanView = ({ assessmentPlan, onBack }) => {
    const [clos, setClos] = useState([]);
    const [fcar, setFcar] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 400);
    const [isSmallishScreen, setIsSmallishScreen] = useState(window.innerWidth <= 600);
    
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 400);
            setIsSmallishScreen(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (assessmentPlan) {
            setLoading(true);
            setError(null);
            Promise.all([fetchCLOs(), fetchFCAR()])
                .then(() => setLoading(false))
                .catch(err => {
                    console.error('Error fetching data:', err);
                    setError('Failed to load data');
                    setLoading(false);
                });
        }
    }, [assessmentPlan]); 

    useEffect(() => {
        if (fcar) {
            fetchDocuments();
        }
    }, [fcar]);

    const fetchCLOs = async () => {
        const response = await axios.get(`${URL}getCLOsByAssessmentPlan/${assessmentPlan.AssessID}`);
        setClos(response.data);
    };

    const fetchFCAR = async () => {
        const response = await axios.get(`${URL}getFCARByAssessmentPlan/${assessmentPlan.AssessID}`);
        setFcar(response.data);
    };

    const fetchDocuments = async () => {
        const response = await axios.get(`${URL}getDocumentsByFCAR/${fcar.FCARID}`);
        const documentsWithBase64 = response.data.map(doc => ({
            ...doc,
            DocumentURL: `data:${doc.MimeType};base64,${doc.AddDocument}`
        }));
        setDocuments(documentsWithBase64);
    };

    const handleDeleteDocument = async (documentID) => {
        try {
            await axios.delete(`${URL}deleteDocument`, {
                data: {
                    documentID : documentID,
                }
            });
            alert("Document deleted successfully.");
            fetchDocuments(); 
        } catch (error) {
            console.error('Error deleting document:', error);
            setError('Error deleting document');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const groupedDocuments = documents.reduce((acc, doc) => {
        if (!acc[doc.DocType]) {
            acc[doc.DocType] = [];
        }
        acc[doc.DocType].push(doc);
        return acc;
    }, {});

    return (
        <div>
            <button onClick={onBack}>Back</button>
            <h2>Assessment Plan Details</h2>
            <h3>CLOs</h3>
            {clos.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th title="The Number for the associated Student Outcome">{!isSmallScreen && !isSmallishScreen ? 'SO Number' : 'S#'}</th>
                             <th title="The Number for this Course Learning Objective">{!isSmallScreen && !isSmallishScreen ? 'CLO Number' : 'C#'}</th>
                             <th title="How is this Course Learning Objective being measured?">{!isSmallScreen && !isSmallishScreen ? 'Measure Name' : 'Type'}</th>
                             <th>{!isSmallScreen && !isSmallishScreen ? 'Measure Description' : 'Desc'}</th>
                             <th title="Description of this Course Learning Objective">{!isSmallScreen && !isSmallishScreen ? 'CLO Comments' : 'CLO'}</th>
                             <th title="Excellent: Students who scored between 90% - 100% on this Course Learning Objective">{!isSmallScreen && !isSmallishScreen ? 'Excellent Count' : 'E'}</th>
                             <th title="Good: Students who scored between 80% - 89% on this Course Learning Objective">{!isSmallScreen && !isSmallishScreen ? 'Good Count' : 'G'}</th>
                             <th title="Satisfactory: Students who scored between 70 – 79% on this Course Learning Objective">{!isSmallScreen && !isSmallishScreen ? 'Satisfactory Count' : 'S'}</th>
                             <th title="Unsatisfactory: Students who scored less than 69% on this Course Learning Objective">{!isSmallScreen && !isSmallishScreen ? 'Unsatisfactory Count' : 'U'}</th>
                             <th title="Null: Students who turned nothing in for this Course Learning Objective">{!isSmallScreen && !isSmallishScreen ? 'Null Count' : 'N'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clos.map(clo => (
                            <tr key={clo.CLOID}>
                                <td>{clo.SONumber}</td>
                                <td>{clo.CLONumber}</td>
                                <td>{clo.MeasureName}</td>
                                <td>{clo.MeasureDescription}</td>
                                <td>{clo.CLOComments}</td>
                                <td>{clo.ExcellentCount}</td>
                                <td>{clo.GoodCount}</td>
                                <td>{clo.SatisfactoryCount}</td>
                                <td>{clo.UnsatisfactoryCount}</td>
                                <td>{clo.NullCount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className='PCenter'>No CLOs available for this assessment plan.</p>
            )}

            <h3>FCAR</h3>
            {fcar ? (
                <div className="fcar-text-container">
                    <label>Modifications</label>
                    {fcar.Modifications ? <p>{fcar.Modifications}</p> : <p className='PCenter'>No modifications available.</p>}
                    
                    <label>Reflection</label>
                    {fcar.Reflection ? <p>{fcar.Reflection}</p> : <p className='PCenter'>No reflection available.</p>}
                    
                    <label>Student Feedback</label>
                    {fcar.StudentFeedback ? <p>{fcar.StudentFeedback}</p> : <p className='PCenter'>No student feedback available.</p>}
                    
                    <label>Improvement</label>
                    {fcar.Improvement ? <p>{fcar.Improvement}</p> : <p className='PCenter'>No improvements available.</p>}
                </div>
            ) : (
                <p className='PCenter'>No FCAR data available.</p>
            )}
            <h3>Documents</h3>
            {Object.keys(groupedDocuments).length > 0 ? (
                Object.keys(groupedDocuments).map(type => (
                    <div key={type} className="document-type-container">
                    <h4 className="document-type-title">{type}</h4>
                    
                    <div className="image-grid">
                    {groupedDocuments[type].map(doc => (
                        <div key={doc.DocumentID} className="document-item">
                        <div className="image-container">
                            <div className="document-hover-actions">
                            <button
                                onClick={() => handleDeleteDocument(doc.DocumentID)}
                                className="delete-button"
                            >
                                Delete
                            </button>
                            <a
                                href={doc.DocumentURL}
                                download={doc.DocumentName}
                                rel="noopener noreferrer"
                            >
                                <button className="download-button">Download</button>
                            </a>
                            </div>
                            <label>{doc.DocumentName}</label>
                            {doc.MimeType.startsWith('image/') ? (
                            <img src={doc.DocumentURL} alt={doc.DocumentName} />
                            ) : (
                            <img src={placeholder} alt={doc.DocumentName} />
                            )}
                        </div>
                        </div>
                    ))}
                    </div>
                </div>
                ))
            ) : (
                <p className='PCenter'>No documents available for this FCAR.</p>
            )}
        </div>
    );
}

export default AssessmentPlanView;
