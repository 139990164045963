import React from 'react';

const FCARMainTable = ({ fcarData, onViewChange, isAdmin, onToggleApproval }) => {
    const handleViewChange = (view, fcarId) => {
        const fcarDataFiltered = fcarData.find(fcar => fcar.FCARID === fcarId);
        onViewChange(view, fcarDataFiltered);
    };

    return (
        <div id="MainTable">
            <table>
                <thead>
                    <tr>
                        <th className="optional-column">Year</th>
                        <th title="Course Number / Section Number">Course/Section</th>
                        <th title="Is this FCAR approved for usage?">Approved?</th>
                        <th title="Click to modify the scores for measures associated with this FCAR">Measures</th>
                        <th title="Click to modify the reflections for this FCAR">Reflection</th>
                        <th title="Click to add documents to this FCAR">Documents</th>
                        <th className="optional-column">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {fcarData.map(fcar => (
                        <tr key={fcar.FCARID}>
                            <td className="optional-column">{fcar.FCARYear}</td>
                            <td>{fcar.CourseNum}/{fcar.SectionNum}</td>
                            <td>{fcar.FCARApproved ? 'Yes' : 'No'}</td>
                            <td>
                                <button onClick={() => handleViewChange('measures', fcar.FCARID)}>Measures</button>
                            </td>
                            <td>
                                <button onClick={() => handleViewChange('reflections', fcar.FCARID)}>Reflection</button>
                            </td>
                            <td>
                                <button onClick={() => handleViewChange('documents', fcar.FCARID)}>Documents</button>
                            </td>
                            <td className="optional-column">
                                {isAdmin && (
                                    <button onClick={() => onToggleApproval(fcar.FCARID, !fcar.FCARApproved)}>
                                        {fcar.FCARApproved ? 'Unapprove' : 'Approve'}
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default FCARMainTable;
