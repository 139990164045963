// src/pages/ReportsPage.js
import React from 'react';
import ThresholdManager from '../components/ThresholdManager';
import SOManager from '../components/SOManager';

const ReportsPage = () => {
  return (
    <div className="main-content">
      <h1>Reports</h1>
      <hr />
      <ThresholdManager />
      <SOManager />
    </div>
  );
};

export default ReportsPage;
