import React from 'react';
import ViewAssessmentManager from '../components/ViewAssessmentManager';

const ViewAssessmentsPage = () => {
    return (
        <div className="main-content">
            <h1>View Assessment Plans</h1>
            <ViewAssessmentManager />
        </div>
    );
};

export default ViewAssessmentsPage;