import React, { useState, useEffect } from 'react';
import axios from 'axios';

const URL = window.env.REACT_APP_API_URL;

const FCARMeasuresView = ({ fcar, onViewChange, fetchFcarData }) => {
    const [measureData, setMeasureData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 400);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 400);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (fcar) {
            setLoading(true);
            setError(null);
            try {
                setMeasureData(fcar.CLOs || []);
                setLoading(false);
            } catch (err) {
                console.error('Error loading measure data:', err);
                setError('Failed to load measure data.');
                setLoading(false);
            }
        }
    }, [fcar]);

    const handleMeasureSubmit = async () => {
        setLoading(true);
        // Submit updated measure data
        try {
            const response = await axios.post(`${URL}updateCLOPerformance`, measureData);
            if (response.data.success) {
                setLoading(false);
                fetchFcarData();
                onViewChange('main');
            } else {
                setLoading(false);
                setError('Failed to update performance');
                console.error('Failed to update performance:', response.data.error);
            }
        } catch (error) {
            setLoading(false);
            setError('Failed to update performance');
            console.error('Error updating performance:', error);
        }
    };

    const handleInputChange = (e, cloID, field) => {
        const updatedData = measureData.map(item => {
            if (item.CLOID === cloID) {
                return { ...item, [field]: e.target.value };
            }
            return item;
        });
        setMeasureData(updatedData);
    };

    const renderCourseInfo = (fcar) => (
        <tr>
            <td>{fcar.FCARYear}</td>
            <td>{fcar.CourseNum}</td>
            <td>{fcar.SectionNum}</td>
            <td>{fcar.FacultyName}</td>
        </tr>
    );

    const hasCLOID = measureData.some(item => item.CLOID);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div id="measureView">
            <button onClick={() => onViewChange('main')}>Return</button>
            <table>
                <thead>
                    <tr>
                        <th title="The year of the associated assessment plan">Year</th>
                        <th title="The course number of the associated assessment plan">Course</th>
                        <th title="The section number of the associated assessment plan">Section</th>
                        <th title="The instructor who associated with this assessment plan">Instructor</th>
                    </tr>
                </thead>
                <tbody>
                    {renderCourseInfo(fcar)}
                </tbody>
            </table>
            {hasCLOID ? (
                <table>
                    <thead>
                        <tr>
                            <th colSpan="7"><h2>Measures</h2></th>
                        </tr>
                    </thead>
                    <tbody>
                        {measureData.map(CLO => (
                            <React.Fragment key={CLO.CLOID}>
                                <tr>
                                    <td colSpan="7">CLO {CLO.CLONumber} - {CLO.CLOComments}</td>
                                </tr>
                                <tr>
                                    <td>{CLO.MeasureName}</td>
                                    <td>{CLO.MeasureDescription}</td>
                                    <td>
                                        {!isSmallScreen && 'Excellent:'}
                                        {isSmallScreen && 'E'}
                                        <br />
                                        <input
                                            type="number"
                                            min="0"
                                            value={CLO.ExcellentCount}
                                            onChange={(e) => handleInputChange(e, CLO.CLOID, 'ExcellentCount')}
                                            className="smallInput"
                                        />
                                    </td>
                                    <td>
                                        {!isSmallScreen && 'Good:'}
                                        {isSmallScreen && 'G'}
                                        <br />
                                        <input
                                            type="number"
                                            min="0"
                                            value={CLO.GoodCount}
                                            onChange={(e) => handleInputChange(e, CLO.CLOID, 'GoodCount')}
                                            className="smallInput"
                                        />
                                    </td>
                                    <td>
                                        {!isSmallScreen && 'Satisfactory:'}
                                        {isSmallScreen && 'S'}
                                        <br />
                                        <input
                                            type="number"
                                            min="0"
                                            value={CLO.SatisfactoryCount}
                                            onChange={(e) => handleInputChange(e, CLO.CLOID, 'SatisfactoryCount')}
                                            className="smallInput"
                                        />
                                    </td>
                                    <td>
                                        {!isSmallScreen && 'Unsatisfactory:'}
                                        {isSmallScreen && 'U'}
                                        <br />
                                        <input
                                            type="number"
                                            min="0"
                                            value={CLO.UnsatisfactoryCount}
                                            onChange={(e) => handleInputChange(e, CLO.CLOID, 'UnsatisfactoryCount')}
                                            className="smallInput"
                                        />
                                    </td>
                                    <td>
                                        {!isSmallScreen && 'Null:'}
                                        {isSmallScreen && 'N'}
                                        <br />
                                        <input
                                            type="number"
                                            min="0"
                                            value={CLO.NullCount}
                                            onChange={(e) => handleInputChange(e, CLO.CLOID, 'NullCount')}
                                            className="smallInput"
                                        />
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className='PCenter'>There are no CLOs associated with this course.</p>
            )}
            <button onClick={handleMeasureSubmit}>Submit</button>
        </div>
    );
};

export default FCARMeasuresView;
