// src/pages/AdminPage.js
import React from 'react';
import YearManagement from '../components/admin/YearManagement';
import CourseManagement from '../components/admin/CourseManagement';
import UseCourseConnectionManager from '../components/admin/UseCourseConnectionManager';
import SectionManagement from '../components/admin/SectionManagement';
import SOManagement from '../components/admin/SOManagement';
import CLOManagement from '../components/admin/CLOManagement';
import SOCLOConnectionManager from '../components/admin/SOCLOConnectionManager';
import MeasureManager from '../components/admin/MeasureManager';
import AdminNavigation from '../components/admin/AdminNavigation';

const AdminPage = () => {
  return (
    <div className="admin-page">
      <div className="admin-sidebar">
        <AdminNavigation />
      </div>
      <div className="main-content">
        <h1>Admin Actions</h1>
        <hr />
        <div id="year-management">
          <YearManagement />
        </div>
        <hr />
        <div id="course-management">
          <CourseManagement />
        </div>
        <hr />
        <div id="use-course-connection-manager">
          <UseCourseConnectionManager />
        </div>
        <hr />
        <div id="section-management">
          <SectionManagement />
        </div>
        <hr />
        <div id="so-management">
          <SOManagement />
        </div>
        <hr />
        <div id="clo-management">
          <CLOManagement />
        </div>
        <hr />
        <div id="soclo-connection-manager">
          <SOCLOConnectionManager />
        </div>
        <hr />
        <div id="measure-manager">
          <MeasureManager />
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
