import React, { useState, useEffect } from 'react';
import axios from 'axios';
import YearSelector from './YearSelector';
import CourseSelector from './CourseSelector';
import AssessmentPlanTable from './AssessmentPlanTable';
import CreateAssessmentPlan from './admin/CreateAssessmentPlan';
import { useAuth } from '../context/AuthContext';

const URL = window.env.REACT_APP_API_URL;

const AssessmentPlanManager = () => {
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedCourse, setSelectedCourse] = useState('');
    const [assessmentPlans, setAssessmentPlans] = useState([]);
    const [filteredPlans, setFilteredPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user, isAdmin } = useAuth();

    useEffect(() => {
        fetchAssessmentPlans();
    }, []);

    useEffect(() => {
        filterAssessmentPlans();
    }, [selectedYear, selectedCourse, assessmentPlans]);

    const fetchAssessmentPlans = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${URL}getAssessmentPlans/${user.UserID}`);
            let plans = response.data;
            if (!isAdmin) {
                plans = plans.filter(plan => plan.Approved);
            }
            setAssessmentPlans(plans);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching assessment plans:', error);
            setError('Failed to load assessment plans');
            setLoading(false);
        }
    };

    const filterAssessmentPlans = () => {
        let plans = assessmentPlans;
        if (selectedYear) {
            plans = plans.filter(plan => plan.CYear === parseInt(selectedYear, 10));
        }
        if (selectedCourse) {
            plans = plans.filter(plan => plan.CourseID === parseInt(selectedCourse, 10));
        }
        setFilteredPlans(plans);
    }

    const handlePlanCreated = () => {
        fetchAssessmentPlans(); // Refresh the list when a new plan is created
    };

    const toggleApproval = async (assessID, approved) => {
        try {
            const response = await axios.post(`${URL}toggleAssessApproval`, {
                assessID,
                approved,
                userID: user.UserID
            });
            if (response.data.success) {
                fetchAssessmentPlans();
            } else {
                console.error('Failed to toggle approval:', response.data.error);
            }
        } catch (error) {
            console.error('Error toggling approval:', error);
        }
    };

    const handleDelete = async (assessID) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this assessment plan?");
        
        if (isConfirmed) {
            try {
                const response = await axios.delete(`${URL}deleteAssessmentPlan/${assessID}`);
                if (response.data.success) {
                    alert('Assessment plan deleted successfully');
                    fetchAssessmentPlans();
                } else {
                    console.error('Failed to delete assessment plan:', response.data.error);
                    alert('Failed to delete assessment plan');
                }
            } catch (error) {
                console.error('Error deleting assessment plan:', error);
                alert('Failed to delete assessment plan');
            }
        }
    };    

    return (
        <div>
            <hr />
            {isAdmin && <CreateAssessmentPlan onPlanCreated={handlePlanCreated} />}
            <h2>View Assessment Plans</h2>
            <YearSelector onSelectYear={setSelectedYear} />
            <CourseSelector onSelectCourse={setSelectedCourse} />

            {loading && <div>Loading...</div>}
            {error && <div>Error: {error}</div>}

            {!loading && !error && (
                filteredPlans.length > 0 ? (
                    <AssessmentPlanTable
                        assessmentPlans={filteredPlans}
                        onToggleApproval={toggleApproval}
                        onHandleDelete={handleDelete}
                        isAdmin={isAdmin}
                    />
                ) : (
                    <p className='PCenter'>No assessment plans available for the selected criteria.</p>
                )
            )}
        </div>
    );
};

export default AssessmentPlanManager;