// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminPage from './pages/AdminPage';
import HomePage from './pages/HomePage';
import AssessmentPage from './pages/AssessmentPage';
import CreateFCARPage from './pages/FCARPage';
import ReportsPage from './pages/ReportsPage';
import UserManagementPage from './pages/UserManagementPage';
import Header from './components/Header';
import LoginPage from './pages/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import ViewAssessmentsPage from './pages/ViewAssessmentsPage';
import MyProfile from './pages/MyProfile';
import './App.css';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/login"
            element={
              <>
                <Header />
                <LoginPage />
              </>
            }
          ></Route>
          <Route
            path="/admin"
            element={
              <>
                <PrivateRoute adminOnly>
                  <Header />
                  <AdminPage />
                </PrivateRoute>
              </>
            }
          ></Route>
          <Route
            path="/UserManagement"
            element={
              <>
                <PrivateRoute adminOnly>
                  <Header />
                  <UserManagementPage />
                </PrivateRoute>
              </>
            }
          ></Route>
          <Route
            path="/home"
            element={
              <>
              <PrivateRoute>
                <Header />
                <HomePage />
              </PrivateRoute>
              </>
            }
          ></Route>
          <Route
            path="/assessment"
            element={
              <>
              <PrivateRoute>
                <Header />
                <AssessmentPage />
              </PrivateRoute>
              </>
            }
          ></Route>
          <Route
            path="/create-fcar"
            element={
              <>
              <PrivateRoute>
                <Header />
                <CreateFCARPage />
              </PrivateRoute>
              </>
            }
          ></Route>
          <Route
            path="/reports"
            element={
              <>
              <PrivateRoute>
                <Header />
                <ReportsPage />
              </PrivateRoute>
              </>
            }
          ></Route>
          <Route
            path="/viewassessments/:assessID?"
            element={
              <>
                <PrivateRoute>
                  <Header />
                  <ViewAssessmentsPage />
                </PrivateRoute>
              </>
            }
          ></Route>
          <Route
          path="/myProfile"
          element={
            <>
              <PrivateRoute>
                <Header />
                <MyProfile />
              </PrivateRoute>
            </>
          }
          ></Route>
          <Route
          path="/"
          element={
            <>
            <PrivateRoute>
              <Header />
              <HomePage />
            </PrivateRoute>
            </>
          }
          ></Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
