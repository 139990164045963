import React from 'react';
import ProfileManager from '../components/ProfileManager';

const MyProfile = () => {
    return (
        <div className="main-content">
            <h1>My Profile</h1>
            <ProfileManager />
        </div>
    );
};

export default MyProfile;