import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import YearSelector from './YearSelector';
import AssessmentPlanList from './AssessmentPlanList';
import AssessmentPlanView from './AssessmentPlanView';
import { useAuth } from '../context/AuthContext';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, Table, TableRow, TableCell, TextRun } from "docx";

const URL = window.env.REACT_APP_API_URL;

const ViewAssessmentManager = () => {
    const { assessID } = useParams();
    const [selectedYear, setSelectedYear] = useState('');
    const [assessmentPlans, setAssessmentPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        fetchAssessmentPlans();
    }, [selectedYear]);

    const fetchAssessmentPlans = async () => {
        setLoading(true);
        setError(null);
        let response;
        try {
            if (selectedYear !== '') {
                response = await axios.get(`${URL}getAssessmentPlansByYear/${user.UserID}/${selectedYear}`);
            } else {
                response = await axios.get(`${URL}getAssessmentPlansByYear/${user.UserID}`);
            }
            setAssessmentPlans(response.data);
            // If there is a provided assessID, it will open the view if the user has access to it
            if (assessID) {
                const selected = response.data.find(plan => plan.AssessID === parseInt(assessID, 10));
                setSelectedPlan(selected);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching assessment plans:', error);
            setError('Failed to load assessment plans');
            setLoading(false);
        }
    };

    const handleDownloadInfo = async (assessmentPlan) => {
        try {
            const zip = new JSZip();

            const CLOResponse = await axios.get(`${URL}getCLOsByAssessmentPlan/${assessmentPlan.AssessID}`);
            const FCARResponse = await axios.get(`${URL}getFCARByAssessmentPlan/${assessmentPlan.AssessID}`);
            const DocumentsResponse = await axios.get(`${URL}getDocumentsByFCAR/${FCARResponse.data.FCARID}`);

            const CLOs = CLOResponse.data;
            const FCAR = FCARResponse.data;
            const documents = DocumentsResponse.data;

            const doc = new Document({sections: []});

            const cloTableRows = [
                new TableRow({
                    children: [
                        new TableCell({ children: [new Paragraph({ text: "SO-CLO Mapping", alignment: "center" })], columnSpan: 2 }),
                        new TableCell({ children: [new Paragraph({ text: "Measures", alignment: "center" })], columnSpan: 2 }),
                        new TableCell({ children: [new Paragraph({ text: "Counts of Student Performance Categories", alignment: "center" })], columnSpan: 5 }),
                    ],
                }),
            ];
            
            cloTableRows.push(
                new TableRow({
                    children: [
                        new TableCell({ children: [new Paragraph({ text: "SO Number", alignment: "center" })] }),
                        new TableCell({ children: [new Paragraph({ text: "CLO Number", alignment: "center" })] }),
                        new TableCell({ children: [new Paragraph({ text: "Measure Type", alignment: "center" })] }),
                        new TableCell({ children: [new Paragraph({ text: "Measure Description", alignment: "center" })] }),
                        new TableCell({
                            children: [new Paragraph({ text: "Excellent (Assignment Score: 90% - 100%)", alignment: "center" })],
                            shading: { fill: "93C47D" },
                        }),
                        new TableCell({
                            children: [new Paragraph({ text: "Good (Assignment Score: 80% - 89%)", alignment: "center" })],
                            shading: { fill: "FFD966" },
                        }),
                        new TableCell({
                            children: [new Paragraph({ text: "Satisfactory (Assignment Score: 70% - 79%)", alignment: "center" })],
                            shading: { fill: "F6B26B" },
                        }),
                        new TableCell({
                            children: [new Paragraph({ text: "Unsatisfactory (Assignment Score: <70%)", alignment: "center" })],
                            shading: { fill: "E06666" },
                        }),
                        new TableCell({
                            children: [new Paragraph({ text: "Null (No Submission)", alignment: "center" })],
                            shading: { fill: "CCCCCC" },
                        }),
                    ],
                })
            );
            
            CLOs.forEach(clo => {
                cloTableRows.push(
                    new TableRow({
                        children: [
                            new TableCell({ children: [new Paragraph({ text: clo.SONumber.toString(), alignment: "center" })] }),
                            new TableCell({ children: [new Paragraph({ text: clo.CLONumber.toString(), alignment: "center" })] }),
                            new TableCell({ children: [new Paragraph({ text: clo.MeasureName.toString(), alignment: "center" })] }),
                            new TableCell({ children: [new Paragraph({ text: clo.MeasureDescription.toString(), alignment: "center" })] }),
                            new TableCell({ children: [new Paragraph({ text: clo.ExcellentCount.toString(), alignment: "center" })] }),
                            new TableCell({ children: [new Paragraph({ text: clo.GoodCount.toString(), alignment: "center" })] }),
                            new TableCell({ children: [new Paragraph({ text: clo.SatisfactoryCount.toString(), alignment: "center" })] }),
                            new TableCell({ children: [new Paragraph({ text: clo.UnsatisfactoryCount.toString(), alignment: "center" })] }),
                            new TableCell({ children: [new Paragraph({ text: clo.NullCount.toString(), alignment: "center" })] }),
                        ],
                    })
                );
            });            

            doc.addSection({
                children: [
                    new Paragraph({
                        text: `Assessment Plan: ${assessmentPlan.CourseNum}-${assessmentPlan.SectionNum} (${assessmentPlan.CYear})`,
                        heading: "Title",
                        alignment: "center",
                    }),
                    new Paragraph({ text: "" }), // Empty line
                    new Paragraph({ text: "CLO Statistics", heading: "Heading1" }),
                    new Table({ rows: cloTableRows }),
                    new Paragraph({ text: "" }), // Empty line
                    new Paragraph({ text: "FCAR Data", heading: "Heading1" }),
                    new Paragraph({ text: `Modifications: ${FCAR.Modifications || "N/A"}` }),
                    new Paragraph({ text: `Reflection: ${FCAR.Reflection || "N/A"}` }),
                    new Paragraph({ text: `Student Feedback: ${FCAR.StudentFeedback || "N/A"}` }),
                    new Paragraph({ text: `Improvements: ${FCAR.Improvement || "N/A"}` }),
                ],
            });

            const docBlob = await Packer.toBlob(doc);
            zip.file(`Assessment_Plan_${assessmentPlan.CourseNum}_${assessmentPlan.SectionNum}.docx`, docBlob);

            for (const doc of documents) {
                const blob = base64ToBlob(doc.AddDocument, doc.MimeType);
                zip.file(doc.DocumentName, blob);
            }

            const zipBlob = await zip.generateAsync({ type: "blob" });
            saveAs(zipBlob, `Assessment_Plan_${assessmentPlan.CourseNum}_${assessmentPlan.SectionNum}.zip`);
        } catch (error) {
            console.error("Error downloading assessment info:", error);
            alert("Failed to download assessment info");
        }
    }

    const base64ToBlob = (base64Data, contentType) => {
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: contentType });
    };

    return (
        <div>
            <YearSelector onSelectYear={setSelectedYear} />
            {loading && <div>Loading...</div>}
            {error && <div>Error: {error}</div>}
            {!loading && !error && (
                <>
                    {selectedPlan ? (
                        <AssessmentPlanView 
                            assessmentPlan={selectedPlan} 
                            onBack={() => setSelectedPlan(null)} 
                        />
                    ) : (
                        assessmentPlans.length > 0 ? (
                            <AssessmentPlanList 
                                assessmentPlans={assessmentPlans} 
                                onSelectPlan={setSelectedPlan} 
                                handleDownloadInfo={handleDownloadInfo}
                            />
                        ) : (
                            <p className='PCenter'>No assessment plans available for the selected year.</p>
                        )
                    )}
                </>
            )}
        </div>
    );
};

export default ViewAssessmentManager;
