import React from 'react';
import CIDMLogo from '../images/cidmlogo.png';

const Home = () => {
    return (
        <div className="home-page">
            <section className='home-title'>
                <h2>ABET Accreditation</h2>
            </section>
            <section className="welcome-section">
                <hr />
                <h3>Welcome to the Program Assessment Reporting System</h3>
                <div className="image-container homeIMG">
                    <img src={CIDMLogo} alt="CIDM Logo" />
                </div>
                <p>The WTAMU Computer Information and Data Management (CIDM) program is accredited by the ABET Computer Accreditation Commission. The Program Assessment Reporting System has been implemented to facilitate the assessment and continuous improvements processes required for ABET accreditation.</p>
                <p>The Program Assessment Reporting System contains links to the WTAMU College of Business and CIDM Department Mission Statements. It also contains the departments Program Educational Objectives, Program Student Outcomes and their association to each course and its subsequent course learning outcomes. The Department Mission is achieved through Program Educational Objectives and Student Outcomes.</p>
            </section>
            <section className="terminologies-section">
                <hr />
                <h3>Terminologies used in the Program Assessment Reporting System:</h3>
                <ol>
                    <li><b>Program Education Objectives(PEOs) :  </b> is a term used to describe the professional accomplishments that the CIDM program is preparing its graduates to achieve.</li>
                    <li><b>Student Outcomes(SOs) : </b> are the outcomes that the CIDM department would like for its students to possess when they go out and join the workforce.</li>
                    <li><b>Course Learning Outcomes(CLOs) : </b> is a term that is used to describe the characteristics of an outcome. CLOs are for the most part implemented in courses. CLOs are used to describe the SOs in more details.</li>
                </ol>
            </section>
            <section className="assessment-measurements-section">
                <hr />
                <h3>Assessment Measurements</h3>
                <h4>Direct Measurements</h4>
                <p><b>Exams, Rubric, Projects, Presentations, Homework Assignments, Programming Assignments, Quizzes, and Other.</b></p>
                <p>Professors evaluate their students depending on one or all of the above mentioned direct measures which directly attribute to a Course Learning Outcome, which in turn contributes to the Program Student Outcome.</p>
                <p>In 2015, the CIDM department adopted the following revised performance scale for CLO measurements when scores on an assessment item is used:</p>
                <ul>
                    <li>Exemplary: 90-100</li>
                    <li>Good: 80-89</li>
                    <li>Satisfactory: 70-79</li>
                    <li>Unsatisfactory: 0-69</li>
                </ul>
                <p>In all other cases, rubrics which specify expected outcomes and performance levels of Exemplary, Good, Satisfactory, Poor, and Unsatisfactory should be used.</p>
                <h4>Indirect Measurements</h4>
                <p><b>Alumni Survey, Employer Survey, Faculty Survey.</b></p>
            </section>
        </div>
    );
}

export default Home;