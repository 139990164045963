// src/utils/auth.js
import { serialize, parse } from 'cookie';

export const setUserSession = (user, isAdmin) => {
    const userCookie = serialize('thisUser', JSON.stringify(user), {
        maxAge: 6 * 60 * 60, // 6 hours
        path: '/',
    });

    const isAdminCookie = serialize('isAdmin', isAdmin, {
        maxAge: 6 * 60 * 60, // 6 hours
        path: '/',
    });

    document.cookie = userCookie;
    document.cookie = isAdminCookie;

    // Set session storage
    sessionStorage.setItem('thisUser', JSON.stringify(user));
    sessionStorage.setItem('isAdmin', isAdmin);
};

export const getUserSession = () => {
    // Try to get from cookies first
    const cookies = parse(document.cookie);
    const userFromCookie = cookies.thisUser ? JSON.parse(cookies.thisUser) : null;
    const isAdminFromCookie = cookies.isAdmin === 'true';

    if (userFromCookie) {
        return { user: userFromCookie, isAdmin: isAdminFromCookie };
    }

    // Fallback to session storage
    const userFromSession = sessionStorage.getItem('thisUser') ? JSON.parse(sessionStorage.getItem('thisUser')) : null;
    const isAdminFromSession = sessionStorage.getItem('isAdmin') === 'true';

    return { user: userFromSession, isAdmin: isAdminFromSession };
};

export const clearUserSession = () => {
    // Clear cookies
    document.cookie = serialize('thisUser', '', {
        maxAge: -1,
        path: '/',
    });
    document.cookie = serialize('isAdmin', '', {
        maxAge: -1,
        path: '/',
    });

    // Clear session storage
    sessionStorage.removeItem('thisUser');
    sessionStorage.removeItem('isAdmin');
};
