import React from 'react';

const AssessmentPlanList = ({ assessmentPlans, onSelectPlan, handleDownloadInfo }) => {
    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th title="The year of this assessment plan" className="optional-column">Year</th>
                        <th title="Course Number / Section Number" >Course/Section</th>
                        <th title="The name of the course this assessment plan is associated with" >Course Name</th>
                        <th title="The name of the faculty member this assessment plan is associated with" >Faculty</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {assessmentPlans.map(plan => (
                        <tr key={plan.AssessID}>
                            <td className="optional-column">{plan.CYear}</td>
                            <td>{plan.CourseNum}/{plan.SectionNum}</td>
                            <td>{plan.CourseName}</td>
                            <td>{plan.FullName}</td>
                            <td>
                                <button onClick={() => onSelectPlan(plan)}>
                                    View Details
                                </button>
                                <button onClick={() => handleDownloadInfo(plan)}>
                                    Download Info
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default AssessmentPlanList;
