import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useAuth } from '../../context/AuthContext';
import { selectStyles } from '../../selectStyle';

const URL = window.env.REACT_APP_API_URL;

const SectionManagement = () => {
    const [courses, setCourses] = useState([]);
    const [sections, setSections] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [newSectionNum, setNewSectionNum] = useState('');
    const [editSectionNum, setEditSectionNum] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const coursesResponse = await axios.get(`${URL}courses/${user.UserID}`);
            setCourses(coursesResponse.data);

            const sectionsResponse = await axios.get(`${URL}getSections/${user.UserID}`);
            setSections(sectionsResponse.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError("Failed to load data.");
        } finally {
            setLoading(false);
        }
    };

    const handleCourseChange = (selectedOption) => {
        setSelectedCourse(selectedOption);
    };

    const handleSectionChange = (selectedOption) => {
        setSelectedSection(selectedOption);
        const section = sections.find(sec => sec.SectionID == selectedOption.value);
        if (section) {
            setEditSectionNum(section.SectionNum);
            setSelectedCourse({ value: section.CourseID, label: `${section.CourseNum} - ${section.CourseName}` });
        } else {
            setSelectedSection(null);
        }
    };

    const addSection = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.post(`${URL}addSection`, {
                SectionNum: newSectionNum,
                CourseID: selectedCourse.value,
                User: user
            });
            setNewSectionNum('');
            setSelectedCourse(null);
            setSelectedSection(null);
            setEditSectionNum('');
            alert("Section added");
            fetchData();
        } catch (error) {
            console.error("Error adding section:", error);
            setError("Failed to add section.");
        } finally {
            setLoading(false);
        }
    };

    const editSection = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.put(`${URL}editSection`, {
                SectionID: selectedSection.value,
                SectionNum: editSectionNum,
                User: user
            });
            setSelectedSection(null);
            setEditSectionNum('');
            setSelectedCourse(null);
            alert("Section changed");
            fetchData();
        } catch (error) {
            console.error("Error editing section:", error);
            setError("Failed to edit section.");
        } finally {
            setLoading(false);
        }
    };

    const removeSection = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.delete(`${URL}removeSection`, {
                data: {
                    SectionID: selectedSection.value,
                    User: user
                }
            });
            setSelectedSection(null);
            setEditSectionNum('');
            setSelectedCourse(null);
            alert("Section removed");
            fetchData();
        } catch (error) {
            console.error("Error removing section:", error);
            setError("Failed to remove section.");
        } finally {
            setLoading(false);
        }
    };

    const courseOptions = [
        { value: '', label: '-- Select Course --' },
        ...courses.map(course => ({ value: course.CourseID, label: `${course.CourseNum} - ${course.CourseName}` }))
    ];

    const sectionOptions = [
        { value: '', label: '-- Select Section --' },
        ...sections.map(section => ({ value: section.SectionID, label: `${section.CourseNum}/${section.SectionNum}` }))
    ];

    return (
        <section id="sectionManager">
            <h1>Section Management</h1>
            {loading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            <h2>Add Section</h2>
            <form onSubmit={addSection}>
                <input
                    type="number"
                    id="newSectionNum"
                    placeholder="Enter section number"
                    min="0"
                    value={newSectionNum}
                    onChange={(e) => setNewSectionNum(e.target.value)}
                    required
                />
                <Select
                    value={selectedCourse}
                    onChange={handleCourseChange}
                    options={courseOptions}
                    placeholder="-- Select Course --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                <button type="submit" disabled={loading}>Submit</button>
            </form>
            <h2>Edit Section</h2>
            <form onSubmit={editSection}>
                <Select
                    value={selectedSection}
                    onChange={handleSectionChange}
                    options={sectionOptions}
                    placeholder="-- Select Section --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                {selectedSection && (
                    <>
                        <input
                        type="number"
                        id="editSectionNum"
                        placeholder="Enter new section number"
                        min="0"
                        value={editSectionNum}
                        onChange={(e) => setEditSectionNum(e.target.value)}
                        required
                    />
                    <button type="submit" disabled={loading}>Submit</button>
                    </>
                )}
            </form>
            <h2>Remove Section</h2>
            <form onSubmit={removeSection}>
                <Select
                    value={selectedSection}
                    onChange={handleSectionChange}
                    options={sectionOptions}
                    placeholder="-- Select Section --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                <button type="submit" disabled={loading}>Confirm</button>
            </form>
        </section>
    );
}

export default SectionManagement;
