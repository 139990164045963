export const selectStyles = {
  control: (provided, state) => {
    const width = window.innerWidth;

    let controlStyles = {
      ...provided,
      width: '40%',
      margin: '0 auto',
      padding: '2px 8px', // Adjust padding as necessary
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      lineHeight: 'normal',
      boxSizing: 'border-box',
    };

    if (width <= 400) {
      controlStyles = {
        ...controlStyles,
        width: '100%',
        padding: '0px 4px 4px 4px',
        fontSize: '12px',
        minHeight: '30px',
        height: '30px',
      };
    } else if (width <= 768) {
      controlStyles = {
        ...controlStyles,
        width: '80%',
      };
    } else if (width <= 992) {
      controlStyles = {
        ...controlStyles,
        width: '60%',
      };
    }

    return controlStyles;
  },
  menu: (provided, state) => {
    const width = window.innerWidth;

    let menuStyles = {
      ...provided,
      width: '40%',
      margin: '0 auto',
      left: '0',
      transform: 'translateX(75%)',
    };

    if (width <= 300) {
      menuStyles = {
        ...menuStyles,
        width: '100%',
        fontSize: '12px',
        transform: 'translateX(0%)',
      };
    } else if (width <= 768) {
      menuStyles = {
        ...menuStyles,
        width: '80%',
        transform: 'translateX(12.5%)',
      };
    } else if (width <= 992) {
      menuStyles = {
        ...menuStyles,
        width: '60%',
        transform: 'translateX(33%)',
      };
    }

    return menuStyles;
  },
  placeholder: (provided, state) => ({
    ...provided,
    color: 'grey',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'black',
  }),
};
