import React, { useState, useEffect } from 'react';
import axios from 'axios';

const URL = window.env.REACT_APP_API_URL;

const FCARReflectionsView = ({ fcar, onViewChange, fetchFcarData }) => {
    const [reflectionData, setReflectionData] = useState({
        Modifications: '',
        Reflection: '',
        StudentFeedback: '',
        Improvement: '',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (fcar) {
            setLoading(true);
            setError(null);
            try {
                setReflectionData({
                    Modifications: fcar.Modifications,
                    Reflection: fcar.Reflection,
                    StudentFeedback: fcar.StudentFeedback,
                    Improvement: fcar.Improvement,
                });
                setLoading(false);
            } catch (err) {
                console.error('Error loading reflection data:', err);
                setError('Failed to load reflection data.');
                setLoading(false);
            }
        }
    }, [fcar]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setReflectionData({ ...reflectionData, [id]: value });
    };

    const handleReflectionSubmit = async () => {
        setLoading(true);
        const updates = {
            ...reflectionData,
            FCARID: fcar.FCARID,
        };

        try {
            const response = await axios.post(`${URL}updateReflection`, updates);
            if (response.data.success) {
                setLoading(false);
                fetchFcarData();
                onViewChange('main');
            } else {
                setLoading(false);
                setError('Failed to update reflection');
                console.error('Failed to update reflection:', response.data.error);
            }
        } catch (error) {
            setLoading(false);
            setError('Failed to update reflection');
            console.error('Error updating reflection:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div id="reflectionView">
            <button onClick={() => onViewChange('main')}>Return</button>
            <table>
                <thead>
                    <tr>
                        <th title="The year of the associated assessment plan">Year</th>
                        <th title="The course number of the associated assessment plan">Course</th>
                        <th title="The section number of the associated assessment plan">Section</th>
                        <th title="The instructor who associated with this assessment plan">Instructor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{fcar.FCARYear}</td>
                        <td>{fcar.CourseNum}</td>
                        <td>{fcar.SectionNum}</td>
                        <td>{fcar.FacultyName}</td>
                    </tr>
                </tbody>
            </table>
            <table>
                <thead>
                    <tr>
                        <th><h2>Reflection</h2></th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(reflectionData).map((key) => (
                        <tr key={key}>
                            <td><label htmlFor={key}>{key}</label>
                            <textarea id={key} value={reflectionData[key]} onChange={handleInputChange} maxLength="1000" /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={handleReflectionSubmit}>Submit</button>
        </div>
    );
};

export default FCARReflectionsView;
