import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useAuth } from '../../context/AuthContext';
import { selectStyles } from '../../selectStyle';

const URL = window.env.REACT_APP_API_URL;

const SOManagement = () => {
    const [years, setYears] = useState([]);
    const [SOs, setSOs] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [newSONumber, setNewSONumber] = useState('');
    const [newSODescription, setNewSODescription] = useState('');
    const [selectedSO, setSelectedSO] = useState(null);
    const [editSONumber, setEditSONumber] = useState('');
    const [editSODescription, setEditSODescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const yearsResponse = await axios.get(`${URL}years`);
            setYears(yearsResponse.data);

            const SOsResponse = await axios.get(`${URL}getSO/${user.UserID}`);
            setSOs(SOsResponse.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError("Failed to load data");
        } finally {
            setLoading(false);
        }
    };

    const handleSOChange = (selectedOption) => {
        setSelectedSO(selectedOption);
        const SO = SOs.find(so => so.SOID == selectedOption.value);
        if (SO) {
            setEditSONumber(SO.SONumber);
            setEditSODescription(SO.SODescription);
        } else {
            setSelectedSO(null);
        }
    };

    const addSO = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.post(`${URL}addSO`, {
                CYear: selectedYear.value,
                SONumber: newSONumber,
                SODescription: newSODescription,
                User: user
            });
            setSelectedYear(null);
            setNewSONumber('');
            setNewSODescription('');
            setEditSONumber('');
            setEditSODescription('');
            alert("Added SO");
            fetchData();
        } catch (error) {
            console.error("Error adding SO:", error);
            setError("Failed to add SO");
        } finally {
            setLoading(false);
        }
    };

    const editSO = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.put(`${URL}editSO`, {
                SOID: selectedSO.value,
                SONumber: editSONumber,
                SODescription: editSODescription,
                User: user
            });
            setSelectedSO(null);
            setEditSONumber('');
            setEditSODescription('');
            setSelectedYear(null);
            setNewSONumber('');
            setNewSODescription('');
            alert("Changed SO");
            fetchData();
        } catch (error) {
            console.error("Error editing SO:", error);
            setError("Failed to edit SO");
        } finally {
            setLoading(false);
        }
    };

    const removeSO = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.delete(`${URL}removeSO`, {
                data: {
                    SOID: selectedSO.value,
                    User: user
                }
            });
            setSelectedSO(null);
            setSelectedYear(null);
            setNewSONumber('');
            setNewSODescription('');
            setEditSONumber('');
            setEditSODescription('');
            alert("Removed SO");
            fetchData();
        } catch (error) {
            console.error("Error removing SO:", error);
            setError("Failed to remove SO");
        } finally {
            setLoading(false);
        }
    };

    const yearOptions = [
        { value: '', label: '-- Select Year --' },
        ...years.map(year => ({ value: year.CYear, label: year.CYear.toString() }))
    ];

    const soOptions = [
        { value: '', label: '-- Select SO --' },
        ...SOs.map(SO => ({ value: SO.SOID, label: `${SO.CYear}: ${SO.SONumber}` }))
    ];

    return (
        <section id="SOManager">
            <h1>SO Management</h1>
            {loading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            <h2>Add SO</h2>
            <form onSubmit={addSO}>
                <Select
                    value={selectedYear}
                    onChange={setSelectedYear}
                    options={yearOptions}
                    placeholder="-- Select Year --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                <input
                    type="number"
                    id="newSONumber"
                    placeholder="Enter SO number"
                    min="0"
                    value={newSONumber}
                    onChange={(e) => setNewSONumber(e.target.value)}
                    required
                />
                <textarea
                    id="newSODescription"
                    placeholder="Enter SO description"
                    maxLength="500"
                    value={newSODescription}
                    onChange={(e) => setNewSODescription(e.target.value)}
                ></textarea>
                <button type="submit" disabled={loading}>Submit</button>
            </form>
            <h2>Edit SO</h2>
            <form onSubmit={editSO}>
                <Select
                    value={selectedSO}
                    onChange={handleSOChange}
                    options={soOptions}
                    placeholder="-- Select SO --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                {selectedSO && (
                    <>
                        <input
                        type="number"
                        id="editSONumber"
                        placeholder="Enter new SO number"
                        min="0"
                        value={editSONumber}
                        onChange={(e) => setEditSONumber(e.target.value)}
                        required
                    />
                    <textarea
                        id="editSODescription"
                        placeholder="Enter new SO description"
                        maxLength="500"
                        value={editSODescription}
                        onChange={(e) => setEditSODescription(e.target.value)}
                    ></textarea>
                    <button type="submit" disabled={loading}>Submit</button>
                    </>
                )}
            </form>
            <h2>Remove SO</h2>
            <form onSubmit={removeSO}>
                <Select
                    value={selectedSO}
                    onChange={handleSOChange}
                    options={soOptions}
                    placeholder="-- Select SO --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                <button type="submit" >Confirm</button>
            </form>
        </section>
    );
}

export default SOManagement;