import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useAuth } from '../context/AuthContext';
import { selectStyles } from '../selectStyle';

const URL = window.env.REACT_APP_API_URL;

const SectionSelector = ({ onSelectSection }) => {
    const [sections, setSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState(null);
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const sectionsResponse = await axios.get(`${URL}getSections/${user.UserID}`);
            setSections(sectionsResponse.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError("Failed to load sections.");
            setLoading(false);
        }
    }

    const handleSectionChange = (selectedOption) => {
        setSelectedSection(selectedOption);
        onSelectSection(selectedOption ? selectedOption.value : '');
    };

    const sectionOptions = [
        { value: '', label: '-- Select Section --' },
        ...sections.map(section => ({
            value: section.SectionID,
            label: `${section.CourseNum}/${section.SectionNum}`
        }))
    ];

    if (loading) {
        return <div>Loading sections...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Select
            value={selectedSection}
            onChange={handleSectionChange}
            options={sectionOptions}
            placeholder="-- Select Section --"
            isSearchable
            styles={selectStyles}
        />
    );
}

export default SectionSelector;