import React from 'react';
import UserManagement from '../components/admin/UserManagement';

const UserManagementPage = () => {
    return (
        <div className="main-content">
            <h1>User Manager</h1>
            <hr />
            <UserManagement />
        </div>
    );
};

export default UserManagementPage;