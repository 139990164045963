import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';

const URL = window.env.REACT_APP_API_URL;

const ThresholdManager = () => {
    const [threshold, setThreshold] = useState(0);
    const [newThreshold, setNewThreshold] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { isAdmin } = useAuth();

    useEffect(() => {
        fetchThreshold();
    }, []);

    const fetchThreshold = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${URL}getThreshold`);
            setThreshold(response.data.Threshold);
            setNewThreshold(response.data.Threshold);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching threshold:', error);
            setError('Error fetching threshold');
            setLoading(false);
        }
    };

    const updateThreshold = async () => {
        const parsedThreshold = parseInt(newThreshold, 10);
        if (isNaN(parsedThreshold)) {
            console.error('Invalid input: Threshold must be a number');
            return;
        }
        try {
            const response = await axios.post(`${URL}setThreshold`, { threshold: parsedThreshold  });
            if (response.data.success) {
                setThreshold(parsedThreshold);
                window.location.reload();
            } else {
                console.error('Failed to update threshold:', response.data.error);
                setError('Failed to update threshold.');
            }
        } catch (error) {
            console.error('Error updating threshold:', error);
            setError('Failed to update threshold.');
        }
    };

    if (loading) {
        return <div>Loading threshold...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <p className='PCenter'>Threshold: {threshold}</p>
            {isAdmin && (
                <div>
                    <input 
                        type="number"
                        value={newThreshold}
                        onChange={(e) => setNewThreshold(e.target.value)}
                        min="0"
                        max="100"
                        className = "smallInput"
                    />
                    <button onClick={updateThreshold}>Update</button>
                </div>
            )}
        </div>
    );
};

export default ThresholdManager;