// src/pages/HomePage.js
import React from 'react';
import Home from '../components/Home';

const HomePage = () => {
  return (
    <div className="main-content">
      <Home />
    </div>
  );
};

export default HomePage;
