import React, { useState, useEffect } from 'react';
import YearSelector from '../YearSelector';
import SectionSelector from '../SectionSelector';
import Select from 'react-select';
import { selectStyles } from '../../selectStyle';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';

const URL = window.env.REACT_APP_API_URL;

const CreateAssessmentPlan = ({ onPlanCreated }) => {
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedSection, setSelectedSection] = useState('');
    const [selectedFaculty, setSelectedFaculty] = useState('');
    const [faculty, setFaculty] = useState([]);
    const [connections, setConnections] = useState([]);
    const [filteredFaculty, setFilteredFaculty] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user, isAdmin } = useAuth();

    useEffect(() => {
        const fetchFacultyAndConnections = async () => {
            try {
                const facultyResponse = await axios.get(`${URL}getFaculty/${user.UserID}`);
                const connectionsResponse = await axios.get(`${URL}getConnections/${user.UserID}`);
                setFaculty(facultyResponse.data);
                setConnections(connectionsResponse.data);
            } catch (err) {
                console.error('Error fetching faculty:', err);
                setError('Failed to load faculty options.');
            }
        };

        fetchFacultyAndConnections();
    }, [user.UserID]);

    useEffect(() => {
        if (selectedSection && connections.length > 0) {
            const filtered = connections
                .filter(conn => conn.CourseID.toString() === selectedSection.toString()) 
                .map(conn => faculty.find(fac => fac.UserID.toString() === conn.UserID.toString()))
                .filter(fac => fac !== undefined);
                
            setFilteredFaculty(filtered);
        }
    }, [selectedSection, connections, faculty]);

    const handleCreate = async () => {
        if (!selectedYear || !selectedSection || !selectedFaculty) {
            alert('Please select a year, a section, and faculty.');
            return;
        }

        if (!isAdmin) {
            alert("You do not have permission to create assessment plans.");
            return;
        }

        setLoading(true);
        setError(null);

        try {
            // Fetch existing assessment plans for the selected year and section
            const response = await axios.get(`${URL}getAssessmentPlans/${user.UserID}`);
            const existingPlans = response.data;

            // Check if a plan with the same year and section already exists
            const duplicate = existingPlans.some(plan => 
                plan.CYear === parseInt(selectedYear, 10) && 
                plan.SectionID === parseInt(selectedSection, 10)
            );

            if (duplicate) {
                alert('An assessment plan with this year and section already exists.');
                setLoading(false);
                return;
            }

            // If no duplicate is found, proceed to create the assessment plan
            const createResponse = await axios.post(`${URL}addAssessmentPlan`, {
                year: selectedYear,
                section: selectedSection,
                faculty: selectedFaculty.value,
                User: user
            });

            if (createResponse.data.success) {
                const assessID = createResponse.data.assessID;

                await axios.post(`${URL}generateFCAR`, {
                    AssessID: assessID,
                    User: user
                });

                onPlanCreated();

                alert("Assessment Plan Created. It’s FCAR was also created and can be found on the FCAR page.");
            } else {
                console.error('Failed to create assessment plan:', createResponse.data.error);
                setError('Failed to create assessment plan.');
            }
        } catch (error) {
            console.error('Error creating assessment plan:', error);
            setError('Failed to create assessment plan.');
        } finally {
            setLoading(false);
        }
    };
    return (
        isAdmin && (
            <div>
                <h2>Create Assessment Plan</h2>
                <YearSelector onSelectYear={setSelectedYear} />
                <SectionSelector onSelectSection={setSelectedSection} />
                <Select
                    value={selectedFaculty}
                    onChange={setSelectedFaculty}
                    options={filteredFaculty.map(fac => ({ value: fac.UserID, label: fac.FullName }))}
                    placeholder="-- Select Faculty --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                {loading && <p>Creating assessment plan...</p>}
                {error && <p className="error">{error}</p>}
                <button onClick={handleCreate} disabled={loading}>
                    Create Assessment Plan
                </button>
                <hr />
            </div>
        )
    );
};

export default CreateAssessmentPlan;