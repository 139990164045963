import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import logo from '../images/SPIRIT-COB-CIRCLE-white.png';

const Header = () => {
  const { isAdmin, logout, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current path
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header>
      <img src={logo} alt="Logo" className="header-logo" />
      {location.pathname !== '/login' && user && (
        <div className="nav-links">
          <Link to="/home">Home</Link> 
          <Link to="/assessment">Assessment Plan</Link> 
          <Link to="/create-fcar">Create FCAR</Link> 
          <Link to="/reports">Reports</Link>
          <Link to="/viewassessments">View Assessments</Link>
          {isAdmin && <Link to="/admin">Admin Actions</Link>}
          {isAdmin && <Link to="/UserManagement">User Management</Link>}
          <Link to="/myProfile">My Profile</Link>
          <Link onClick={handleLogout}>Sign Out</Link>
        </div>
      )}
      {location.pathname !== '/login' && (
        <div className="dropdown">
          <button onClick={toggleDropdown} className="dropdown-toggle">☰</button>
          {dropdownOpen && (
            <div className="dropdown-menu">
              <Link to="/home" onClick={toggleDropdown}>Home</Link> 
              <Link to="/assessment" onClick={toggleDropdown}>Assessment Plan</Link> 
              <Link to="/create-fcar" onClick={toggleDropdown}>Create FCAR</Link> 
              <Link to="/reports" onClick={toggleDropdown}>Reports</Link>
              <Link to="/viewassessments" onClick={toggleDropdown}>View Assessments</Link>
              {isAdmin && <Link to="/admin" onClick={toggleDropdown}>Admin Actions</Link>}
              {isAdmin && <Link to="/UserManagement" onClick={toggleDropdown}>User Management</Link>}
              <Link to="/myProfile" onClick={toggleDropdown}>My Profile</Link>
              <Link onClick={() => {handleLogout(); toggleDropdown();}}>Sign Out</Link>
            </div>
          )}
        </div>
      )}
    </header>
  );
};

export default Header;
