import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';

const URL = window.env.REACT_APP_API_URL;

const UserManagement = () => { //add the listeners
    const [ users, setUsers ] = useState([]);
    const [newUser, setNewUser] = useState({
        username: '',
        fullName: '',
        email: '',
        password: '',
        employmentStatus: 1
    });
    const [editUser, setEditUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user, isAdmin } = useAuth();

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${URL}getUsers`, {
                params: {
                    checkUser: user.UserID
                }
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users: ', error);
            setError('Failed to fetch users');
        } finally {
            setLoading(false);
        }
    };

    const toggleActiveStatus = async (targetID) => {
        if (targetID === user.UserID) {
            const confirmation = window.confirm("You are about to deactivate your own account. You will not be able to sign back in. Are you sure?");
            if (!confirmation) {
                return; 
            }
        }
    
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post(`${URL}toggleActiveStatus`, {
                User: user,
                targetID: targetID
            });
    
            if (response.data.success) {
                fetchUsers();
            } else {
                console.error('Failed to toggle active status:', response.data.error);
                setError('Failed to toggle user status');
            }
        } catch (error) {
            console.error('Error toggling active status:', error);
            setError('Failed to toggle user status');
        } finally {
            setLoading(false);
        }
    };
    

    const handleRoleToggle = async (role, targetID, currentStatus) => {
        const isSelfAction = targetID === user.UserID;
        let confirmMessage = `Are you sure you want to ${currentStatus ? 'remove' : 'grant'} ${role} status?`;

        if (isSelfAction && currentStatus && role === 'Admin') {
            confirmMessage = "You are about to remove your own admin status. Are you absolutely sure?";
        }

        if (window.confirm(confirmMessage)) {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.post(`${URL}toggleRoleStatus`, {
                    User: user,
                    role: role,
                    targetID: targetID,
                    currentStatus: currentStatus
                });

                if (response.data.success) {
                    fetchUsers();
                } else {
                    console.error(`Failed to toggle ${role} status:`, response.data.error);
                    setError(`Failed to toggle ${role} status`);
                }
            } catch (error) {
                console.error(`Error toggling ${role} status:`, error);
                setError(`Failed to toggle ${role} status`);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditUser({ ...editUser, [name]: value });
    };

    const handleAddUser = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post(`${URL}addUser`, {
                User: user,
                newUser: newUser
            });

            if (response.data.success) {
                fetchUsers();
                setNewUser({ username: '', fullName: '', email: '', password: '', employmentStatus: 1 });
            } else {
                console.error('Failed to add user:', response.data.error);
                setError('Failed to add user');
            }
        } catch (error) {
            console.error('Error adding user:', error);
            setError('Failed to add user');
        } finally {
            setLoading(false);
        }
    };

    const handleEditUser = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post(`${URL}editUser`, {
                User: user,
                editUser: editUser
            });

            if (response.data.success) {
                fetchUsers();
                setEditUser(null);  // Close edit form
            } else {
                console.error('Failed to edit user:', response.data.error);
                setError('Failed to edit user');
            }
        } catch (error) {
            console.error('Error editing user:', error);
            setError('Failed to edit user');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteUser = async (userID) => {
        const isSelfAction = userID === user.UserID;
        let confirmMessage = "Are you sure you want to delete this user?";
    
        if (isSelfAction) {
            confirmMessage = "You are about to delete your own account. This action cannot be undone. Are you sure?";
        }
    
        if (window.confirm(confirmMessage)) {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.delete(`${URL}deleteUser/${userID}`, {
                    params: { adminUserID: user.UserID }
                });
    
                if (response.data.success) {
                    fetchUsers();
                } else {
                    console.error('Failed to delete user:', response.data.error);
                    setError('Failed to delete user');
                }
            } catch (error) {
                console.error('Error deleting user:', error);
                setError('Failed to delete user');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        isAdmin && (
            <div>
                {loading && <p>Loading...</p>}
                {error && <p className="error">{error}</p>}

                <h2>Add New User</h2>
                <form>
                    <input
                        type="text"
                        name="username"
                        value={newUser.username}
                        onChange={handleInputChange}
                        placeholder="Username"
                        required
                    />
                    <input
                        type="text"
                        name="fullName"
                        value={newUser.fullName}
                        onChange={handleInputChange}
                        placeholder="Full Name"
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        value={newUser.email}
                        onChange={handleInputChange}
                        placeholder="Email"
                        required
                    />
                    <input
                        type="password"
                        name="password"
                        value={newUser.password}
                        onChange={handleInputChange}
                        placeholder="Password"
                        required
                    />
                    <select
                        name="employmentStatus"
                        value={newUser.employmentStatus}
                        onChange={handleInputChange}
                    >
                        <option value={1}>Active</option>
                        <option value={0}>Inactive</option>
                    </select>
                    <button type="button" onClick={handleAddUser} disabled={loading}>
                        Add User
                    </button>
                </form>

                <hr />

                {!editUser && users.length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <th title="Their Username">Username</th>
                                <th className="optional-column" title="Their Full Name">Full Name</th>
                                <th title="Their Email (Should be a WTAMU Email)">Email</th>
                                <th className="optional-column" title="Deactivated Users cannot sign in, best used for closing un-needed accounts but maintaining the data that may be associated with them">Status</th>
                                <th title="Shows their current status with each possible role, press the button to grant or revoke them">Roles</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user => (
                                <tr key={user.UserID}>
                                    <td>{user.Username}</td>
                                    <td className="optional-column">{user.FullName}</td>
                                    <td>{user.WTEmail}</td>
                                    <td className="optional-column">{user.EmploymentStatus ? 'Active' : 'Inactive'}</td>
                                    <td>
                                        <button
                                            onClick={() => handleRoleToggle('Faculty', user.UserID, user.isFaculty)}
                                            disabled={loading}
                                        >
                                            {user.isFaculty ? 'Remove Faculty' : 'Grant Faculty'}
                                        </button>
                                        <button
                                            onClick={() => handleRoleToggle('Admin', user.UserID, user.isAdmin)}
                                            disabled={loading}
                                        >
                                            {user.isAdmin ? 'Remove Admin' : 'Grant Admin'}
                                        </button>
                                    </td>
                                    <td>
                                        <button onClick={() => toggleActiveStatus(user.UserID)} disabled={loading}>
                                            {user.EmploymentStatus ? 'Deactivate' : 'Activate'}
                                        </button>
                                        <button onClick={() => setEditUser(user)}>Edit</button>
                                        <button onClick={() => handleDeleteUser(user.UserID)} disabled={loading}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    !editUser && <p className='PCenter'>No users found.</p>
                )}
                {editUser && (
                    <div>
                        <h2>Edit User</h2>
                        <form>
                            <input
                                type="text"
                                name="Username"
                                value={editUser.Username}
                                onChange={handleEditInputChange}
                                placeholder="Username"
                                required
                            />
                            <input
                                type="text"
                                name="FullName"
                                value={editUser.FullName}
                                onChange={handleEditInputChange}
                                placeholder="Full Name"
                                required
                            />
                            <input
                                type="email"
                                name="WTEmail"
                                value={editUser.WTEmail}
                                onChange={handleEditInputChange}
                                placeholder="Email"
                                required
                            />
                            <select
                                name="EmploymentStatus"
                                value={editUser.EmploymentStatus}
                                onChange={handleEditInputChange}
                            >
                                <option value={1}>Active</option>
                                <option value={0}>Inactive</option>
                            </select>
                            <button type="button" onClick={handleEditUser} disabled={loading}>
                                Update User
                            </button>
                            <button type="button" onClick={() => setEditUser(null)}>
                                Cancel
                            </button>
                        </form>
                    </div>
                )}
            </div>
        )
    );
    
}

export default UserManagement;