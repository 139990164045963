import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const URL = window.env.REACT_APP_API_URL;

const ProfileManager = () => {
    const { user, updateUser } = useAuth();
    const [profile, setProfile] = useState({
        username: '',
        fullName: '',
        email: '',
        currentPassword: '',
        newPassword: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchProfile();
    }, []);

    const fetchProfile = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${URL}getUserProfile/${user.UserID}`);
            setProfile({
                username: response.data.Username,
                fullName: response.data.FullName,
                email: response.data.WTEmail,
                currentPassword: '',
                newPassword: ''
            });
            setLoading(false);
        } catch (error) {
            setError('Error fetching profile');
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile({ ...profile, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedProfile = {
                UserID: user.UserID,
                Username: profile.username,
                FullName: profile.fullName,
                WTEmail: profile.email,
                currentPassword: profile.currentPassword,
                newPassword: profile.newPassword
            };
            await axios.put(`${URL}updateUserProfile`, updatedProfile);
            updateUser(updatedProfile);
            alert('Profile updated successfully')
        } catch (error) {
            alert(`Error updating profile: ${error}`);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <form onSubmit={handleSubmit} id="myProfileCenter">
            <div>
                <label>
                    Username:
                    <input
                        type="text"
                        name="username"
                        value={profile.username}
                        onChange={handleInputChange}
                        required
                    />
                </label>
            </div>
            <div>
                <label>
                    Full Name:
                    <input
                        type="text"
                        name="fullName"
                        value={profile.fullName}
                        onChange={handleInputChange}
                        required
                    />
                </label>
            </div>
            <div>
                <label>
                    Email:
                    <input
                        type="email"
                        name="email"
                        value={profile.email}
                        onChange={handleInputChange}
                        required
                    />
                </label>
            </div>
            <div>
                <label>
                    Current Password (for changing the password):
                    <input
                        type="password"
                        name="currentPassword"
                        value={profile.currentPassword}
                        onChange={handleInputChange}
                    />
                </label>
            </div>
            <div>
                <label>
                    New Password:
                    <input
                        type="password"
                        name="newPassword"
                        value={profile.newPassword}
                        onChange={handleInputChange}
                    />
                </label>
            </div>
            <button type="submit">Save Changes</button>
        </form>
    );
};

export default ProfileManager;