import React, { useState } from 'react';
import axios from 'axios';

const URL = window.env.REACT_APP_API_URL;

const FCARDocumentsView = ({ fcar, onViewChange }) => {
    const [rubric, setRubric] = useState(null);
    const [worksheet, setWorksheet] = useState(null);
    const [other, setOther] = useState(null);
    const [syllabus, setSyllabus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (name === 'rubric') setRubric(files[0]);
        else if (name === 'worksheet') setWorksheet(files[0]);
        else if (name === 'other') setOther(files[0]);
        else if (name === 'syllabus') setSyllabus(files[0]);
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append('FCARID', fcar.FCARID);
        if (rubric) formData.append('rubric', rubric);
        if (worksheet) formData.append('worksheet', worksheet);
        if (other) formData.append('other', other);
        if (syllabus) formData.append('syllabus', syllabus);

        try {
            const response = await axios.post(`${URL}uploadDocs`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.success) {
                setLoading(false);
                onViewChange('main');
            } else {
                setError('Failed to upload documents');
                setLoading(false);
                console.error('Failed to upload documents:', response.data.error);
            }
        } catch (error) {
            setError('Error uploading documents');
            setLoading(false);
            console.error('Error uploading documents:', error);
        }
    };

    return (
        <div id="docView">
            <button onClick={() => onViewChange('main')}>Return</button>
            <table>
                <thead>
                    <tr>
                        <th title="The year of this FCAR">Year</th>
                        <th title="The Course of this FCAR">Course</th>
                        <th title="The Section of this FCAR">Section</th>
                        <th title="The instructor associated with this FCAR">Instructor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{fcar.FCARYear}</td>
                        <td>{fcar.CourseNum}</td>
                        <td>{fcar.SectionNum}</td>
                        <td>{fcar.FacultyName}</td>
                    </tr>
                </tbody>
            </table>
            <h2>Documents</h2>
            {loading && <div>Uploading documents...</div>}
            {error && <div>Error: {error}</div>}
            {!loading && (
                <form id="documentForm" onSubmit={handleFormSubmit}>
                    <input type="hidden" name="FCARID" id="FCARID" value={fcar.FCARID} />
                    <label htmlFor="rubric">Upload Rubric:</label>
                    <input type="file" id="rubric" name="rubric" onChange={handleFileChange} />
                    <br />
                    <label htmlFor="worksheet">Upload Worksheet:</label>
                    <input type="file" id="worksheet" name="worksheet" onChange={handleFileChange} />
                    <br />
                    <label htmlFor="other">Upload Other:</label>
                    <input type="file" id="other" name="other" onChange={handleFileChange} />
                    <br />
                    <label htmlFor="syllabus">Upload Syllabus:</label>
                    <input type="file" id="syllabus" name="syllabus" onChange={handleFileChange} />
                    <br />
                    <button type="submit" id="documentSubmit">Submit</button>
                </form>
            )}
        </div>
    );
};

export default FCARDocumentsView;
