// src/pages/AssessmentPage.js
import React from 'react';
import AssessmentPlanManager from '../components/AssessmentPlanManager';

const AssessmentPage = () => {
  return (
    <div className="main-content">
      <h1>Assessment Plans</h1>
      <AssessmentPlanManager />
    </div>
  );
};

export default AssessmentPage;
